/* eslint-disable react/no-danger */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'antd';
import { getTransText } from 'common/translate';
import MainContext from 'stores/context/MainContexts';

const checkMessage = (message) => {
  if (message.endsWith('.')) return message;
  return `${message}.`;
};

const MessagePopup = ({
  defaultLB, description, onChangeAdd, onChangeRemove,
  isAdd, isRemove, addLikebox, undoAdded, undoRemoved,
}) => {
  let timeoutPopUp = null;
  const { initDataProvider } = useContext(MainContext);
  const autoClosePopUpMsg = () => {
    onChangeAdd(false);
    onChangeRemove(false);
  };

  useEffect(() => {
    if (isAdd) {
      timeoutPopUp = setTimeout(autoClosePopUpMsg, 4000);
    }
  }, [isAdd]);
  useEffect(() => {
    if (isRemove) {
      timeoutPopUp = setTimeout(autoClosePopUpMsg, 4000);
    }
  }, [isRemove]);
  const closePopUpMsg = () => {
    clearTimeout(timeoutPopUp);
    onChangeAdd(false);
    onChangeRemove(false);
  };
  // Message added and removed likebox
  const stringAdd = checkMessage(`${description}... Added to <a class="string-add-likebox"><u>${defaultLB}</u></a>`);
  const messageAdd = (
    <div dangerouslySetInnerHTML={{ __html: stringAdd }} />
  );
  const stringRemove = checkMessage(`${description}... Removed from <a><u>${defaultLB}</u></a>`);
  const messageRemove = (
    <div dangerouslySetInnerHTML={{ __html: stringRemove }} />
  );
  return (
    <>
      <div className={addLikebox ? 'message-likebox' : 'message-likebox scroll-up'}>
        {isAdd && (
        <Alert
          message={messageAdd}
          type="success"
          showIcon
          action={!initDataProvider?.displayBtn && (
            <Button size="small" type="text" onClick={undoAdded}>
              {getTransText('btnUnDo')}
            </Button>
          )}
          closable
          afterClose={closePopUpMsg}
        />
        )}
      </div>
      <div className={addLikebox ? 'message-likebox' : 'message-likebox scroll-up'}>
        {isRemove && (
          <Alert
            message={messageRemove}
            type="success"
            showIcon
            action={!initDataProvider?.displayBtn && (
              <Button size="small" type="text" onClick={undoRemoved}>
                {getTransText('btnUnDo')}
              </Button>
            )}
            closable
            afterClose={closePopUpMsg}
          />
        )}
      </div>
    </>
  );
};
MessagePopup.propTypes = {
  defaultLB: PropTypes.string,
  description: PropTypes.string,
  onChangeAdd: PropTypes.func,
  onChangeRemove: PropTypes.func,
  isAdd: PropTypes.bool,
  isRemove: PropTypes.bool,
  addLikebox: PropTypes.bool,
  undoAdded: PropTypes.func,
  undoRemoved: PropTypes.func,

};

MessagePopup.defaultProps = {
  defaultLB: null,
  description: null,
  onChangeAdd: null,
  onChangeRemove: null,
  isAdd: null,
  isRemove: null,
  addLikebox: null,
  undoAdded: null,
  undoRemoved: null,
};
export default MessagePopup;
