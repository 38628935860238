/* eslint-disable react/forbid-prop-types */
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { getTransText } from 'common/translate';
import {
  ACCESS_TOKEN,
  SHARED_SESSION,
  SUCCESSFUL,
  WHITE_COLOR,
  WIDTH_MOBILE,
} from 'constants/index';
import { API_ROUTES, ROUTES, ROUTES_DISPLAY_STICKY_MY_COLLECTION } from 'constants/routes';
import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import Axios from 'services/Axios/Axios';
import MainContext from 'stores/context/MainContexts';
import PropTypes from 'prop-types';
import ThreeDots from 'components/icons/ThreeDots';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';
import * as cookie from 'helpers/cookies';
import UseWindowWidth from 'helpers/CustomHooks/useWindowWidth';
import Collection from './Collection';
import IcArrowLeft from '../../icons/MyCollection/IcArrowLeft';

const DropdownCollection = memo(
  ({
    imageId,
    sizeImage,
    onCreateNewLikebox,
    fillcolor,
    threeDotsDisplay,
    audioDetail,
    onChangeAdd,
    onChangeRemove,
    getDefaultLikebox,
    descriptionImg,
    dataClone,
    isDetailCollection,
    haveBackBtn,
    containerWidth,
    iconArrow,
  }) => {
    const { actionsUser } = useContext(MainContext);
    const [visible, setVisible] = useState(false);
    const [popupContainer, setPopupContainer] = useState(false);
    const router = useRouter();
    const IconArrow = iconArrow;
    const windowWidth = UseWindowWidth();
    const onChangeVisible = (isVisible) => {
      setVisible(isVisible);
    };
    const checkAuthentication = () => {
      const checkToken = cookie.getCookies(ACCESS_TOKEN);
      const checkLoginSession = cookie.getCookies(SHARED_SESSION);
      if (!checkToken || isEmpty(checkToken) || isEmpty(checkLoginSession)) {
        return false;
      }
      return true;
    };
    const getIsAddedImage = async (idImage) => {
      if (checkAuthentication()) {
        setVisible(true);
        actionsUser.setCollectionData(null);
        await Axios.get(`${API_ROUTES.GET_IS_ADDED_IMAGE}?image_id=${idImage}`, (status, data) => {
          if (data.status === SUCCESSFUL) {
            const data1 = [];
            Object.keys(data?.content).forEach((key) => {
              data1.push(data?.content[key]);
            });
            actionsUser.setCollectionData(data1);
          }
        });
      } else {
        router.push(ROUTES.LOGIN);
      }
    };

    const onBtnBackClicked = () => {
      setVisible(false);
    };

    useEffect(() => {
      if (ROUTES_DISPLAY_STICKY_MY_COLLECTION.includes(router.pathname) || isDetailCollection) {
        setPopupContainer(true);
      }
    }, []);

    return (
      <>
        <Dropdown
          transitionName={windowWidth <= WIDTH_MOBILE ? '' : undefined}
          overlay={
            checkAuthentication() ? (
              <Collection
                imageId={imageId}
                onChangeVisible={onChangeVisible}
                onCreateNewLikebox={onCreateNewLikebox}
                onChangeAdd={onChangeAdd}
                onChangeRemove={onChangeRemove}
                getDefaultLikebox={getDefaultLikebox}
                descriptionImg={descriptionImg}
                dataClone={dataClone}
                haveBackBtn={haveBackBtn}
                onBtnBackClicked={onBtnBackClicked}
              />
            ) : (
              <></>
            )
          }
          overlayClassName="dropdown-collection-overlay responsive-dropdown"
          trigger={['click']}
          placement="bottomCenter"
          // arrow
          visible={visible}
          onVisibleChange={(isVisible) => setVisible(isVisible)}
          onClick={() => getIsAddedImage(imageId)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(popupContainer && {
            getPopupContainer: (trigger) => trigger.parentElement.parentElement.parentElement,
          })}
        >
          {
            // check ratio and min max contaner width to display 3 dots
            parseFloat(sizeImage) <= 1 || (containerWidth && containerWidth < 310) ? (
              <div className="display-flex">
                <ThreeDots />
              </div>
            ) : (
              <div className="default-collection-wrapper">
                {typeof window !== 'undefined' && (
                  <>
                    {window?.innerWidth < 1200 && threeDotsDisplay ? (
                      <ThreeDots fillcolor={fillcolor ?? WHITE_COLOR} />
                    ) : (
                      <>
                        <div
                          className={`default-collection${visible ? ' open' : ''}${
                            audioDetail ? ' detail-page' : ''
                          }`}
                        >
                          {getTransText('defaultCollection')}
                        </div>
                        {iconArrow ? (
                          <IconArrow fillColor="#212121" up={visible} />
                        ) : (
                          <>
                            {!isDetailCollection ? (
                              <DownOutlined className={audioDetail ? 'arrow-up-down' : ''} />
                            ) : (
                              <span className="detail-collection-icon">
                                <IcArrowLeft />
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )
          }
        </Dropdown>
      </>
    );
  },
);
DropdownCollection.propTypes = {
  imageId: PropTypes.number,
  sizeImage: PropTypes.number,
  onCreateNewLikebox: PropTypes.func,
  fillcolor: PropTypes.string,
  threeDotsDisplay: PropTypes.bool,
  audioDetail: PropTypes.bool,
  onChangeAdd: PropTypes.func,
  onChangeRemove: PropTypes.func,
  getDefaultLikebox: PropTypes.func,
  descriptionImg: PropTypes.string,
  dataClone: PropTypes.arrayOf(PropTypes.any),
  isDetailCollection: PropTypes.bool,
  haveBackBtn: PropTypes.bool,
  containerWidth: PropTypes.number,
  iconArrow: PropTypes.any,
};

DropdownCollection.defaultProps = {
  imageId: null,
  sizeImage: null,
  onCreateNewLikebox: null,
  fillcolor: null,
  threeDotsDisplay: false,
  audioDetail: false,
  onChangeAdd: null,
  onChangeRemove: null,
  getDefaultLikebox: null,
  descriptionImg: '',
  dataClone: null,
  isDetailCollection: false,
  haveBackBtn: false,
  containerWidth: null,
  iconArrow: false,
};
export default DropdownCollection;
