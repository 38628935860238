const jp = {
  // Header
  photos: '写真素材',
  Photos: '写真素材',
  vectors: 'イラスト素材',
  Vectors: 'イラスト素材',
  footageupper: '動画',
  footage: '動画',
  video: 'Video',
  audio: '音楽素材',
  signin: 'サインイン',
  imageLogo:
    'https://static-cdn.assetsdelivery.com/images/new_translate_corpplus_logo/logo_Japan.png',
  language: '言語',
  // Footer
  contact: '御社情報',
  contactUs: '御社情報',
  privacy: 'プライバシーポリシー',
  terms: 'ご利用規約',
  cookie: 'クッキーポリシー',
  corporateAgreement: 'ライセンス契約',
  // ForgotPassword
  forgotPwdTitle2: 'パスワードをお忘れですか',
  forgotPwdDesc2:
    "That's OK, it happens to all of us. Enter your username below and we'll send you a link to reset your password",
  forgotPwdFail: 'Eメールアドレスを入力してください。',
  emailCannotEmpty: 'Eメールが空白です。',
  returnToLogin: 'ログインページに戻る',
  email: 'Eメール',

  // share likebox
  duplicateCollection: 'Duplicate collection',
  removeFromCollection: 'Remove from collection',
  addToCollection: 'Add to collection',
  totalContentInThisCollection: 'Total content in this collection',
  duplicate: 'Duplicate',
  collectionDescription: 'Collection description',
  descriptionHere: 'Description here',
  duplicateCollectionSuccess:
    'You have successfully duplicated this collection to your personal collections.',

  // Login
  username: 'ユーザー名(半角英数字のみ)',
  pw: 'パスワード',
  rememberMe: '保存',
  login: 'ログイン',
  forgotPassword: 'パスワードをお忘れですか',
  loginAsGuest: '閲覧のみ',
  // ResetPassword
  resetPwdDesc: '新しいパスワードでログインしてください。',
  newPw: '変更後のパスワード',
  confirmPwd: 'パスワードを確認',
  resetPwdButton: 'パスワードリセット',
  resetPwdTitle: 'パスワードをリセットする',
  enterYourNewPw: '新しいパスワードを入力してください。',
  pwMiniMumChars: '半角英数8文字以上',
  pwMaxChar20: '最大文字数は20字です。',
  pwLowerCase: '1文字以上の小文字',
  pwUpperCase: '1文字以上の大文字',
  pwNumeral: '1文字以上の数字',
  pwdSpecialChar: 'At least one special character',
  confirmPwNotMatch: '確認パスワードがパスワードと一致していません。',
  resetPwdInvalidLink: 'このリンクは時間切れまたは無効です。',
  pwHasBeenReset: 'パスワードは再設定されました。ログインしてください。',
  // Admin index
  companyName: '社名',
  phone: '電話番号',
  fax: 'ファックス',
  address: '住所',
  idd: 'IDD',
  // Dashboard Admin Credits
  myBalance: 'My balance',
  remaining: 'remaining',
  creditsRemaining: 'Credits remaining',
  topUp: 'Top up',
  groupBalance: 'Group balance',
  totalGroupBalance: 'Total group balance',
  manageGroupBalance: 'Manage group balance',
  individualBalance: 'Individual balance',
  manageIndividualBalance: 'Manage individual balance',
  totalDownloads: 'Total downloads',
  totalItems: '合計数',
  // Update Password
  updatePassTitle: 'Update Password',
  updatePassDes: 'Update your password before you go!',
  oldPassword: '現在のパスワード',
  confirmNewPassword: '変更後のパスワードの確認',
  returnToHome: 'ホームに戻る',
  // Cookie Policy
  langCookie01:
    '%host% サイトをご覧いただくと、お客様のコンピュータのハードディスク内のクッキーと呼ばれる小さなテキストファイルに位置づけされます。',
  langCookie02:
    'このファイルを使用すると、ショッピングカートのような基本的な機能を提供し、追跡することができます。 また、「likebox」などの機能を提供するためにも使用されます。',
  langCookie03:
    'さらに、購入をするかどうかにかかわらず、ウェブサイトとのやりとり（訪問したページ、閲覧された検索語句、閲覧された画像など）を分析することができます。',
  langCookie04:
    'お客様が同意したクッキーからの情報は、お客様にあった広告を掲載するためにも利用されています。',
  langCookie05:
    'ほとんどのブラウザでは、Cookieを拒否できます。 そうした場合、特定の機能が利用できなくなり、特定のWebページが正しく表示されないことがあります。',

  downloadModalTitle: '画像ダウンロード',
  bulkDownloadTitle: 'Bulk Download',
  messageFileNotSupported1stHalf:
    'TIFFファイル画像、メディア動画、メディアオーディオは対象外です。 メディアとは：',
  messageFileNotSupported2ndHalf: 'まとめてダウンロードより削除されました。',
  licenseOnBehalf: '代理ダウンロード（クライアントへライセンス譲渡）',
  customizedPortal: 'Customized portal',
  normalDownload: '通常ダウンロード',
  myCredits: 'マイチケット',
  lowCreditsReminder: 'Low credits reminder',
  downloadModalId: 'イメージID:',
  downloadModalPrice: 'チケット:',
  clearAll: 'すべてクリア',
  myActivities: 'マイアクティビティ',
  info: '情報',
  idCapitialization: 'ID',
  licenseOnBehalfOfYourClient: 'License on behalf of your client (Your client owns the license)',
  downloadUsing: 'コンテンツをダウンロード：',
  // homepage page
  home3Title: 'Free Browsing',
  toSearchKeyword: '検索したいキーワードをこちらに入力してください',
  toSearchAIKeyword: 'キーワード、記事のタイトル、またはブログタイトルを入力',
  allImages: 'すべての画像',
  freeImages: 'Free Images',
  categoryImage: 'Category',
  recentViewed: '最近の閲覧履歴',
  latestIMG: 'Latest Images',
  // Setting
  adminControlPanel: '管理画面ログイン',
  downloadHistory: 'ダウンロード履歴',
  hruc: '高解像度カンプ',
  changePassword: 'パスワーﾄﾞ変更',
  logOut: 'ログアウト',
  hello: '様',
  viewAccount: 'View Account',
  myAccount: 'マイアカウント',
  myCollections: 'マイコレクション',
  personalCredits: '個人チケット',
  groupTotal: 'グループチケット',
  toTalUnpaidLicense: '未払いライセンス合計',
  highRUnwatermarkComp: '高解像度カンプ',
  monthSelection: 'Month selection',

  // Menu
  categories: 'カテゴリー',
  myLikebox: 'マイライトボックス',
  settings: '設定',
  notification: 'お知らせ',
  clear: 'クリアする',
  likebox: '收藏夹',
  credits: 'チケット',
  // Credit summary at header
  downloadAndUsege: 'チケット残高',
  totalDlCredit: 'トータル利用チケット数',
  allocatedDlCredit: '割り当て済みチケット数',
  personalAvailableCre: '個人の利用可能チケット数',
  groupAvailableCre: 'グループ内の利用可能チケット数',
  availableDlSlot: '利用可能数',
  uNPLD: '利用分のみ後払いシステムでのダウンロード数',
  hiResHRUC: '高解像度カンプ',
  monthlyAllocation: '月ごとの割り当て数',
  remainingQuota: '残数',
  remainingHRUCQ: '今月利用可能な高解像度カンプ残数',
  useNowPayLater: 'Use-Now-Pay-Later (UNPL)',
  totalPendingLicense: 'Total Pending License Downloads',
  enterprisePlan: 'Corporate+ Enterprise Plan',
  unallocatedCredits: 'Unallocated Credits',
  unutilizedAllocatedCredits: 'Unutilized Allocated Credits',
  usersTitleTotalCredits: 'Users',
  groupsTitleTotalCredits: 'グループ',
  titleTotalCreditsAvailable: 'Total Available',
  // live chat
  liveChatLookIngHelp:
    '英語でライブチャットが利用できます。日本語でのご質問はメールまたはお電話をご利用ください。',
  liveChat: 'ライブチャット（英語）',
  whatsApp: 'WhatsApp',
  hiThere: 'こんにちは,',
  // email response
  emailIsNotAvailable: 'Eメールが無効です。',
  anErrorHaSoccured: 'エラーです。もう一度お試しください。',
  emailHasBeenSent:
    'Eメールが送信されました。Eメールをご確認ください。__email こちらのメールアドレスにリセットリンクを送信しました。 5分以内にメールが届かない場合、迷惑フォルダもご確認ください。',
  tooManyAttempts: 'リクエストが多すぎます。後でもう一度お試しください。',
  // INVALIDPASSWORD
  inValidPassword: 'パスワード有効ではありません。',
  userIsBlocked: '規定回数以上の入力失敗のため、一時的にアカウントがロックされています',
  blockWarrings: '入力を10回失敗すると一時的にアカウントがロックされます',
  // Update Password
  resetPwdUpdated: 'パスワードは変更されました。',
  updatePwDfailed: 'パスワードの変更に失敗しました',
  // notification
  // Notification Side Bar - user
  notificationShareLikebox: 'shared a Likebox named [{VAR1}] with you.',
  notificationOutofCredits: 'ran out of download credits.',
  notificationAllcateCredit: 'assigned [{VAR1}] download credits to you.',
  notificationRemoveCredits: 'removed [{VAR1}] download credits from your account.',
  notificationAssignGroups: 'assigned you into the [{VAR1}] group.',
  notificationRemoveFromGroup: 'removed you from the [{VAR1}] group.',
  purcahsedCredits: 'You have just purchased [{VAR1}] credits.',
  notificationAssignAdmin: 'assigned you as the administrator.',
  notificationRemoveAdmin: 'removed you as the administrator.',
  notificationAssignGroupAdmin: 'assigned you as the group administrator of [{VAR1}].',
  notificationRemoveGroupAdmin: 'removed you as the group administrator of [{VAR1}].',
  // Notification Side Bar - admin
  adminNotificationShareLikebox: 'shared a Likebox named [{VAR1}] with [{RECEIVER}].',
  adminNotificationAllcateCredits: 'assigned [{VAR1}] download credits to [{RECEIVER}].',
  adminNotificationRemoveCredist: "removed [{VAR1}] download credits from [{RECEIVER}]'s account.",
  adminNotificationAssignGroup: 'assigned [{RECEIVER}] into the [{VAR1}] group.',
  adminNotificationRemoveFromGroup: 'removed [{RECEIVER}] from the [{VAR1}] group.',
  adminPurchasedCredits: '[{RECEIVER}] have just purchased [{VAR1}] credits.',
  adminNotificationAssignAdmin: 'assigned [{RECEIVER}] as the administrator.',
  adminNotificationRemoveAdmin: 'removed [{RECEIVER}] as the administrator.',
  adminNotificationAssignGroudAdmin:
    'assigned [{RECEIVER}] as the group administrator of [{VAR1}].',
  adminNotificationremoveGroupAdmin: 'removed [{RECEIVER}] as the group administrator of [{VAR1}].',
  // datetime
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  ago: 'ago',
  justNow: 'just now',
  // datetimes
  years: 'years',
  months: 'months',
  weeks: 'weeks',
  days: 'days',
  hours: 'hours',
  minutes: '分',
  seconds: 'seconds',
  // LicenseAgreement
  licenseAgreement: 'ライセンス契約',
  licenseAgreementCaption:
    'こちらのCORPORATE+コンテンツライセンスプラットフォームに記載されているすべての条件をお読みください。これらの条件に同意することは、CORPORATE +コンテンツライセンスプラットフォームおよびコンテンツの使用のための絶対条件であり、法的契約を形成します。 CORPORATE+コンテンツライセンスプラットフォームの下でサービスまたはその一部にアクセスすることにより、これらの法的文書の条件に拘束されることに同意したことになります。 123RFは、事前の書面による通知なしにいつでもこれらの条件を変更する権利を留保し、お客様はそのような変更に拘束されることに同意するものとします。',
  corpplusAgreement: 'コーポレートプラス契約書',
  // login
  usernameCannotEmpty: 'ユーザー名を入力してください',
  passWordCannotEmpty: 'パスワードが空白です。',
  oldPasswordCannotEmpty: '旧パスワードを入力してください。',
  confirmPasswordCannotBeEmpty: '新しいパスワードを確認欄に入力してください。',
  // update password
  newpasswordDifferent: 'The new pasword and old pasword must be different.',
  // sent email not 20 times
  forgotNot20: 'これ以上メールを送信できません。メールボックスを確認してください。',
  // errorMesServer
  errorMesServer: 'An error has occured, please try again or contact your administrators',
  // white space
  canNotContainSpaces: 'Password cannot contain spaces',
  // username is not available
  userNameNotAvailable: 'Username is not available.',
  // Username cannot contain spaces
  usernameCanNotContainSpaces: 'Username cannot contain spaces',
  // old browsers
  usingOlderBrowsers: '古いブラウザをお使いですか？',
  upgradeBrowser: '新しいブラウザにアップグレードして、より快適に123RFをご利用ください。',
  clickToDownload: '下記のアイコンをクリックして最新版をダウンロード：',
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  microsoftEdge: 'Microsoft Edge',
  safari: 'Safari',
  nameUnavailable: 'Name unavailable',
  addressUnavailable: 'Company address unavailable',
  phoneUnavailable: 'Phone number unavailable',
  faxUnavailable: 'Fax unavailable',
  iddUnavailable: 'IDD unavailable',
  emailUnavailable: 'Email unavailable',
  agreeLicense: 'I AGREE to abide by all the Terms and Conditions above',
  btnAgree: 'AGREE',
  btnDecline: 'DECLINE',
  messageCheckbox:
    'Please read the License Agreement above carefully and signify your agreement by clicking the "I AGREE" check box.',
  // forgot page new description
  forgotPwdDescNew: "Enter your username below and we'll send you a link to reset your password.",
  // Notification
  readAll: 'Read All',
  viewAll: 'すべてを見る',
  // Filter bar
  aiGenerate: 'AIに生成された画像',
  aiContent: 'AIに生成されたコンテンツを含めます',
  filter: '絞り込み',
  safeSearch: 'セーフサーチ',
  aiAssist: 'AIアシスト',
  freshness: '登録日',
  gender: '性別',
  sortBy: '並び順',
  mediaType: 'メディアタイプ',
  orientation: '向き',
  modelPreferences: '好みのモデル',
  people: '人々',
  more: 'もっと',
  numOfPeople: '人数',
  age: '年齢',
  ethnicity: '人種・民族',
  collection: 'コレクション',
  uploaded: 'Uploaded',
  style: 'スタイル',
  cutOut: '単色/透過背景',
  doNotIncludeWords: 'これらの言葉を除く',
  doNotIncludeWords_ex: '例：チーズ',
  color: '色',
  max1Color: '最大１色',
  license: 'ライセンスタイプ',
  fps: 'FPS',
  resolution: '解像度',
  clipDuration: 'クリップ長さ (分)',
  loop: 'ループ',
  category: 'カテゴリ',
  genre: 'ジャンル',
  tempo: 'テンポ（BPM）',
  // Filter button text
  Relevance: '関連度順',
  New: '最新画像',
  Georank: 'Georank',
  DownloadHistory: 'ダウンロード履歴',
  Popularity: '人気',
  Photography: '写真',
  VectorIllustration: 'ベクターイラスト',
  Horizontal: '横長',
  Landscape: '横置き',
  Portrait: '縦置き',
  Square: '正方形',
  Panorama: 'パノラマ',
  numPpl: '人数',
  WithoutPeople: '人物なし',
  FilterNumberPeople1: '1 person',
  FilterNumberPeople2: '2 People',
  FilterNumberPeople3: '3 People',
  FilterNumberPeople4: '4 people or more',
  Exclude: '含まない',
  SelectiveFocus: 'Selective Focus',
  Pattern: 'Pattern',
  Vibrance: 'Vibrance',
  Infants: 'Infants',
  Toddlers: 'Toddlers',
  Teens: 'Teens',
  PastWeek: '過去1週間',
  LastMonth: 'Last month',
  PastThreeMonths: '過去3ヶ月',
  PastTwodays: '過去2日間',
  Male: '男性',
  Female: '女性',
  Standard: 'スタンダード',
  extended: '特別',
  Editorial: 'エディトリアル',
  Commercial: '商用利用',
  Black: 'Black',
  Caucasian: '白人',
  AfricanAmerican: 'アフリカ系アメリカ人',
  Asian: 'アジア人',
  EastAsian: 'East Asian',
  Hispanic: 'ラテン系アメリカ人',
  Indian: 'Indian',
  MiddleEastern: 'Middle Eastern',
  SoutheastAsian: 'Southeast Asian',
  Children: '子供',
  Teenagers: '10代の若者',
  Adults: '大人',
  Seniors: '高齢者',
  FPS1: '23.98/24',
  FPS2: '25',
  FPS3: '29.7/30',
  FPS4: '60',
  FPS5: '> 60',
  Off: 'Off',
  ZeroToOne: '0-1',
  OneToTwo: '1-2',
  TwoToThree: '2-3',
  LagerThree: '>3',
  Loopable: 'はい',
  Nonloopable: 'いいえ',
  Music: '音楽',
  SoundEffects: '効果音',
  LogoAndIdents: 'ロゴ＆アイデンティティ',
  Veryslow: 'とても遅い',
  VeryslowSubText: '(0-79)',
  Slow: '遅い',
  SlowSubText: '(80-119)',
  Medium: '普通',
  MediumSubText: '(120-139)',
  Fast: '速い',
  FastSubText: '(140-159)',
  Veryfast: 'とても速い',
  VeryfastSubText: '(160>)',
  TwoToEight: '2 - 8',
  TwentyToThirTy: '12 - 30',
  ThirtySixToSixty: '30 - 60',
  OneHundredAndTwenty: '120',
  Popular: '人気',
  MostDownload: 'Most Download',
  Credits: 'チケット',
  Freshness: '登録日',
  pickAGenre: 'ジャンルを選択',
  // Paginations
  Of: '分の',
  Page: 'ページ',
  // home
  recommended: 'オススメ',
  bestSellingHome: 'Browse best-selling royalty photos',
  trendingKeyWord: 'トレンドキーワード',
  FAQ: 'Frequently Asked Questions (FAQ)',
  // audio
  categoriesAudio: 'HD stock audios',

  // genre list audios
  gameSounds: 'ゲームサウンド',
  cartoonSounds: 'アニメサウンド',
  soundPacks: 'サウンドパック',
  interfaceSounds: 'インターフェイスサウンド',
  transitionsAndMovementsSounds: 'トランジション&ムーブメント',
  natureSounds: 'ネイチャーサウンド',
  domesticSounds: 'ドメスティックサウンド',
  urbanSounds: 'アーバンサウンド',
  industrialSounds: 'インダストリアルサウンド',
  futuristicSounds: 'フューチャリスティック サウンド',
  humanSounds: "'ヒューマンサウンド",
  othersSounds: 'その他',
  ambientSounds: 'アンビエント',
  childrenSounds: '子供',
  cinematicSounds: '映画',
  classicalSounds: 'クラシック',
  corporateSounds: 'コーポレート',
  electronicaSounds: 'エレクトロニカ',
  soulSounds: 'ソウル、R&B',
  jazzSounds: 'ジャズ',
  popSounds: 'ポップ',
  worldBeatSounds: 'ワールドビート',

  countrySounds: 'カントリー＆ウエスタン',
  drumSounds: 'ドラム&ベース',

  folkSounds: 'フォーク&アコースティック',
  funkSounds: 'ファンク＆グルーブ',

  hiphopSounds: 'ヒップホップ',
  holidaySounds: 'ホリディ&シーズン',

  rockSounds: 'ロック',

  individualSounds: 'ソロ',
  introSounds: 'イントロ&アウトロ',
  ascSounds: 'アセンディング＆ディセンディング',
  businessSounds: 'ビジネス&メディア',
  orchestralSounds: 'オーケストラ',
  festiveSounds: 'フェスティブ',
  percussiveSounds: '陽気',
  miscellaneousSounds: '種々雑多',
  packsSounds: 'パック',
  percussionSounds: 'パーカッション',
  sampleLoopSounds: 'サンプルループ',

  // Search images
  searchImage: '写真＆ベクター',
  StockPhotosAndImages: 'ストックイメージ＆写真素材',
  StockVectorClipartAndIllustration: 'ストックベクター、イラストレーション',
  matches: '一致',
  editorial: 'エディトリアル',
  downloaded: 'この画像は以前ダウンロード済みです',
  to: 'to',
  relatedSearches: '関連検索',
  similarTo: '類似素材',
  searchByImage: 'イメージから画像を探す',
  options: 'オプション',
  searchByImageMsg: '画像から123RFで検索できます。検索ボックスに画像をドラッグしてください。',
  mustBeJPGorPNG: '画像の拡張子はJPG/PNGのみです',
  sizeLessThan5MB: '画像サイズは5MB以内にしてください',
  sizeLessThan4MB: '画像サイズは4MB以内にしてください',
  dragOrDropFile: 'ファイルをドラッグ＆ドロップ、または',
  browse: '参照',
  uploadedImage: 'アップロードした画像',
  similarStockPhotos: '類似した素材',
  // Search footage
  searchFootage: '動画',
  StockFootage: '動画',
  businessFootage: 'Hacks to make the most of your business videos',
  // Details pages
  audioDetail: 'Audio Detail',
  imageDetail: 'Image Detail',
  footageProperties: '動画プロパティ',
  audioProperties: 'オーディオプロパティ',
  modalDownloadTitle: 'ファイルダウンロードオプション',
  credit: 'チケット',
  saveAs: '保存形式',
  ownLicense: '通常ダウンロード（独自ライセンス）',
  downloadAs: 'ダウンロード形式',
  insufficientCredits: 'チケットが不足しています',
  custom: 'カスタム',
  personalAndCom: '個人および商用広告、プレゼンテーション、SNS、ウェブ。',
  licenseType: 'ライセンスタイプ',
  requiredForResale:
    '劇場公演、有料アクセス、ストリーミングビデオ、ゲーム、アプリにて再販または配信する場合に必要です。',
  footageDetail: '動画詳細',
  stockAudioKeywords: 'ストックオーディオキーワード',
  stockFootageKeywords: '動画キーワード',
  stockPhotoKeywords: 'Stock photo keywords',
  limitedLicenseSizes: 'Limited License Sizes',
  stereo: 'ステレオ',
  fileSize: 'ファイルサイズ',
  footageId: '動画ID',
  audioId: 'Audio ID',
  imageId: 'Image ID',
  // Search audio
  searchAudio: 'オーディオ（音楽＆効果音）',
  StockAudio: 'ストックオーディオ',
  similarTracks: 'Similar Tracks',
  viewAlbum: 'View Album',
  copyLink: 'リンクをコピーする',
  preview: 'プレビュー',

  bestSellingFootage: 'ベストセラーのロイヤリティ動画を見る',
  featuresAudio: 'Featured audio',
  readmore: 'Read More',
  // justified layout
  similarImage: '類似画像',
  addToLikebox: 'Add to Likebox',

  // recently view empty data
  rVPhoto: '最近審査された画像がこちらに表示されます。',
  rVVectors: '最近審査されたベクターがこちらに表示されます。',
  rVFootage: '最近審査された動画がこちらに表示されます。',
  rVAudio: '最近審査された音楽がこちらに表示されます。',
  // recently likes empty data
  rLPhoto: 'Your recently liked images will show here',
  rLVectors: 'Your recently liked vectors will show here',
  rLFootage: 'Your recently liked video will show here',
  rLAudio: 'Your recently liked audio will show here',
  // Content Unavailable
  contentUnavailable: 'Content Unavailable',
  contentError: 'We’re sorry, there’s some error displaying this content',
  emptySearchResultTitle: '申し訳ございません。一致する検索結果を得られませんでした。',
  emptySearchResultTitleWithoutFilter: 'Oops, your search did not return any results.',
  emptySearchResultSubTitle: 'フィルターを減らすか別のキーワードでお試しください。',
  emptyReverseSearchTitle: '申し訳ございません。ご希望に沿う画像が見つかりませんでした。',
  emptyReverseSearchSubTitle1: 'アップロード画像にエラーがありました。下記をご確認ください。',
  emptyReverseSearchSubTitle2: '検索キーワードをご確認ください。',
  emptyReverseSearchSubTitle3: 'JPGかPNGフォーマットでアップロードしてください。',
  emptyReverseSearchSubTitle4: 'ファイルサイズは5MB以内でお願いします。',
  emptySearchResultClearFilter: 'フィルターをクリア',
  emptyDownloadHistoryResult: 'Your download history will show here',
  // Please enter a keyword
  keywordEmpty: 'キーワードを入力してください',
  // admin redirect to
  plansAndPricing: 'Plans and Pricing ',
  // Footer langding page
  contentTermLicense1:
    'This Agreement shall be governed by the laws of Singapore, without regard to the conflict of laws principles. ',
  contentTermLicense2:
    'the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“SIAC”) administered SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with these rules. The number of arbitrators shall be one (1). The language of the arbitration shall be English.',
  contentTermLicense3:
    'that damages and remedies under Singapore Law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable laws of Singapore,',
  contentTermUsLicense1:
    'This Agreement shall be governed by the laws of the state of Delaware, United States of America, without regard to the conflict of laws principles',
  contentTermLicenseUs2:
    ' the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration Association under its Commercial Mediation Procedures before resorting to arbitration, litigations or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1) and the language of the arbitration shall be English.',
  contentTermUsLisence3:
    ' that damages and remedies under applicable law for any breach of any such covenant would be inadequate. You further acknowledge and agree that the arbitral tribunal may order any interim measure it deems necessary or proper in accordance with the applicable law,',
  contentCorporateUs:
    'This Agreement shall be governed by the laws of the State of Delaware in the United States of America, without regard to the conflict of laws principles. All disputes arising out of or in connection with the performance of this Agreement shall be settled through negotiations and if the dispute cannot be settled through negotiation, the parties agree first to try in good faith to settle the dispute by mediation administered by the American Arbitration under its Commercial Mediation Procedures before resorting to arbitration, litigation, or some other dispute resolution procedure. In the event if the dispute is settled via arbitration, the number of arbitrators shall be one (1), the language of the arbitration shall be English and the arbitral award shall be final and binding upon parties.',
  contentCorporate:
    'This Agreement shall be governed by, and shall be construed in accordance with, the laws of Singapore. All disputes arising out of or in connection with the performance of this Agreement shall be settled through friendly negotiations. If the parties are unable to resolve any such dispute within thirty (30) days after the commencement of negotiations, the parties agree that the dispute shall be settled by arbitration in Singapore under the Singapore International Arbitration Centre (“<b>SIAC</b>”) administered by SIAC Arbitration Rules in force when the notice of arbitration is submitted in accordance with the rules. The number of arbitrators shall be one (1). The decision of the arbitrator shall, except in the case of manifest error, be final and binding upon the parties hereto. The arbitration proceedings shall be conducted in English.',
  searchCollection: 'コレクションを検索',
  descriptionPlaceholder: 'Add an optional description for your collection here.',
  personal: '個人',
  groups: 'グループ',
  createNew: '新規作成',
  createNewCollection: '新しいコレクションを作成',
  titleItem: 'タイトル',
  selectGroup: 'グループを選択',
  description: '画像詳細',
  charactersRemaining: '残り180文字',
  cancel: 'キャンセル',
  create: '作成',
  defaultCollection: 'デフォルトコレクション',
  addToMyCollections: 'ライクボックスに加える',
  addedToCollection: 'コレクションに追加されました',
  removedFromCollection: 'コレクションから削除されました',
  collectionModalPlaceholder: '例：自然',
  groupNameShowsHere: 'ここにグループ名が表示されます',
  // like box
  btnViewCollection: 'View Collection',
  shareWithMe: '共有されたコレクション',
  addLikebox: 'Add New Likebox',
  notImageLikeBox: 'You do not have any images in your likebox yet',
  owner: '所有者',
  images: '画像',
  likeboxName: 'Likebox Name',
  likboxNameMaxLength50: 'The maximum characters allowed is 50',
  addLikeBoxTo: 'Add likebox to',
  personalLikebox: '個人',
  btAdd: 'ADD',
  btCancel: 'CANCEL',
  btSave: 'SAVE',
  editLikebox: 'Edit Likebox',
  share: '共有',
  // reset password
  resetPwdTitle2: 'Reset Password',
  noFoundLikebox: 'コレクションがありません',
  btnUnDo: '元に戻す',
  // my collection
  dateAdded: '追加日',
  collectionName: 'コレクション名',
  selectAll: 'すべて選択',
  delete: '削除',
  seeAll: 'すべて見る',
  deleteCollection: 'コレクションを削除',
  deleteWarning:
    'このアクションを実行するとコレクションとそれに含まれるコンテンツが完全に削除されます。この作業は取り消すことができません。',
  createCollection: ' が作成されました',
  shareCollection: 'コレクションを共有',
  shareWithTeam: 'Share in team',
  selectUsers: 'ユーザーを選択',
  searchUserName: 'ユーザー名を検索',
  copy: 'コピー',
  titleCopyModal: '選択した画像をコピー：',
  collectionUppercase: 'コレクション',
  groupCollection: 'グループ',
  moveImage: '選択した画像を移動：',
  defaultSelect: 'コレクション名はこちら',
  moveLikebox: 'Move',
  messsageDeleteLikebox:
    'このアクションを実行するとコレクションとそれに含まれるコンテンツが完全に削除されます。この作業は取り消すことができません。',
  areYouSure: '本当に実行しますか？',
  editCollection: 'コレクションを編集',
  collectionNameUpper: 'コレクション名',
  deleteSuccessful: 'has been successfully deleted',
  edit: '編集する',
  messageRemoveImage: '本当に選択したアイテムをコレクションから削除しますか？',
  titleRemove: '選択した画像を削除：',
  popupActionMove: ' item(s) has been moved to ',
  popupActionCopy: ' item(s)has been copied to ',
  popupActionRemove: ' item(s) has been removed from this Collection',
  popupFailed: 'Image already exists',
  popupShared: 'Your collection has been successfully shared',
  popupActionDelete: 'Your collection has been successfully removed',
  popupActionEdit: 'Your edit has been successfully updated',
  btnSave: '保存する',
  shareLikebox: '共有されたコレクション',
  remove: '削除',
  moveTo: '移動：',
  createNewPlaceholder: '例：自然',
  shareViaEmail: 'Eメールで共有',
  sharePublicly: 'Share publicly',
  receipnetEmail: 'メールアドレス',
  message: 'メッセージ',
  insertReceipmentEmail: 'メールアドレスを入力',
  messageText: 'メッセージ内容',
  exportCollection: 'コレクションをエクスポート',
  pdf: 'PDF',
  csv: 'CSV',
  addNewCollection: '新しいコレクションを追加',
  likeboxEditSuccessful: 'You have successfully made changes to your collection.',
  format: 'フォーマット',
  lowCredit: 'チケットが少なくなっています',
  downloadSuccess: 'ダウンロードありがとうございます。ダウンロードはこの後開始されます。',
  downloadFailed: 'ダウンロードエラー エラーです。もう一度お試しください。',
  downloadedLabel: 'ダウンロード',
  any: '何ら',
  resetFilter: '絞り込みをリセット',
  enterCustomFieldTag: 'Enter custom field tags',
  // Portfolio
  follow: 'フォロー',
  name: '名前',
  location: '場所',
  since: '登録日',

  // マイアカウント
  updated: 'Update',
  userName: 'ユーザー名',
  firstName: '名字',
  lastName: '名前',
  myProfile: 'My Profile',
  changeProfiledetail: 'プロフィール詳細をここから変更する',
  userPrivileges: 'User Privileges',
  viewAllDownload: 'View all download history',
  viewDownloadCost: 'View download cost',
  accountManagerInfo: 'Account manager info',
  describeAccountmanager: 'Describe what this account manager do',
  creditsPlan: 'Credits plan',
  myPlan: '現在のプラン',
  myOrganisation: 'My organisation plan',
  myAccountProfileDetails:
    'This are your profile details set by the organisation admin. If you wish to change your credentials, kindly contact admin.',
  usePayLater: '先利用後払い',
  bodyUNPL: '先利用後払い（UNPL）は、ご利用分を毎月末にまとめてご請求するプランです。',
  firstnameCannotEmpty: 'First name cannot be empty.',
  lastnameCannotEmpty: 'Lastname cannot be empty.',

  noSpecialCharacters: 'This field cannot contain special characters',
  messageUpdateProfile: 'You had successfully updated the user information',
  bodyCredit:
    'Vitae morbi tristique luctus sed eget donec nibh in duis neque donec lectus turpis turpis aliquam facilisis mattis laoreet enim',
  contactYourAccount: 'Contact your account manager for further assistane',
  emailMaxLength75: 'The maximum characters allowed is 75',

  // audio player
  categoryGenre: 'カテゴリ / ジャンル',
  duration: '長さ',
  seeMore: 'もっと見る',
  seeLess: '表示アイテムを減らす',
  audioID: 'オーディオID',
  // category
  landscape: '風景',
  Babies: '赤ちゃん',
  business: 'ビジネス',
  food: '食べ物',
  animals: '動物',
  technology: 'テクノロジー',
  healthcare: 'ヘルスケア',
  nature: '自然',
  romance: 'ロマンス',
  // category vector
  graphic: 'グラフィック',
  advertising: '広告',
  flower: '花',
  abstract: '抽象画',
  Illustration: 'イラスト',
  pop: 'ポップ',
  bird: '鳥',
  pixel: 'ピクセル',
  threeD: '3D',

  // footage category
  education: '教育',
  communication: '通信',
  beauty: '美しさ',
  concept: 'コンセプト',
  fitness: 'フィットネス',
  lifestyle: 'ライフスタイル',
  celebration: '祝賀会',
  family: '家族',
  fashion: 'ファッション',

  // audio category
  soulAndRB: 'ソウル、R&B',
  corporate: 'コーポレート',
  electronica: '電子音楽',
  classical: 'クラッシック',
  funkAndGrooveMusic: 'ファンク＆グルーブ音楽',
  worldBeat: 'ワールドビート',
  cinematic: '映画音楽',
  jazz: 'ジャズ',
  flamenco: 'Flamenco',
  mothersDayLove: 'Mothers Day Love',
  happyUplifting: 'Happy Uplifting',
  epicHeroAdventure: 'Epic Hero Adventure',

  // Download-history modal
  reDownload: '再ダウンロード',
  chooseCustomTagOrCreate: 'カスタムタグを選択または新しいタグを作成',
  downloadDetails: 'ダウンロード詳細',
  image: 'Image',
  downloadType: 'ダウンロードタイプ',
  groupName: 'グループ名',
  size: 'サイズ',
  dateDownloaded: 'ダウンロード日',
  reLicense: '再ライセンス',
  customFields: 'カスタム欄',
  saveAndClose: '保存＆閉じる',
  characterMaxChar100: 'The maximum characters allowed is 100',
  characterNotAllow:
    'The maximum characters allowed is 100 and the minimum characters allowed is 1',
  dlwByMember: 'Dowloaded By Members',
  groupInactive: 'Group inactive',
  personalInactive: 'Personal inactive',
  date: 'ダウンロード日',
  export: 'エクスポート',
  selectRange: 'select range',
  fileType: 'file type',
  downloadedLabelUNPL: 'Licensed',
  HRUCTab: 'Hi-res comp',
  searchById: 'IDで検索',
  pleaseSelect: '選択してください',
  totalOutstanding: '総残数',
  viewInvoices: '請求書を見る',
  or: 'または',
  // Image detail page - select download size sidebar
  standardSizes: '標準サイズ',
  extendedLicenseSizes: 'Extended License Sizes',
  crop: '切り抜き',
  // empty data
  opsNothingToSeeHere: 'アップロードされたコンテンツがありません.',
  // search image
  authentic: '現実的',
  // search audio
  mostDownloaded: 'ダウンロード数',
  download: 'ダウンロード',

  // search page
  nextPage: '次のページ',
  reverseImageSearch: 'イメージ検索結果',

  group: 'グループ',
  needHelp: 'お困りですか？',
  copied: 'コピーされました！',
  copyPortfolioLinkSuccess:
    'コントリビューターポートフォリオのリンクがクリップボードにコピーされました.',

  // my activities
  myActivity: 'マイアクティビティ',
  recentLike: '最近のお気に入り',
  recentView: '最近閲覧したコンテンツ',
  myActivityViewDesc: '最近閲覧したコンテンツは３ヶ月まで保存されます',
  myActivityLikeDesc: '過去３ヶ月間の履歴のみが表示されます',

  // dashboard
  profile: 'プロフィール',

  // Pricing
  pricingCreditText: 'チケット',

  // Dashboard - Credit Usage
  user: 'User',
  creditUsage: 'Credit usage',
  searchPlaceholder: 'Search name, username, role, group',
  all: 'すべて',
  active: 'Active',
  deleted: 'Deleted',
  reset: 'リセット',
  totalAllocated: 'Total allocated',
  totalSpent: 'Total spent',
  balance: 'Balance',
  groupLowerCase: 'group',
  roleDefinitions: 'Role definitions',
  superAdmin: 'Super admin',
  admin: 'Admin',
  historySearchPlaceholder: 'Search history',
  somethingWentWrong: 'Something went wrong',
  information: 'Information',
  maximumBulkDownloadWarning: 'Bulk download allows maximum 50 items',
  tooltipCustomLogin: 'Please use at least 8 characters',
  maxLengthTitleCustom: 'Title should not be more than 25 characters',
  maxLengthDescCustom: 'Description should not be more than 50 characters',
  selectdItems: 'Selected media',
  aiPoweredSearch: 'AI検索',
  aiPoweredSearchOn: 'AI検索が有効になっています',
  aiSearchIntroduction:
    'より速く、より強力な検索エンジンをお試しください。残りをAI検索におまかせ！',
  ok: 'OK',
  // Filter bar ai

  bstract: '抽象画',
  macroCloseUp: 'マクロ/クローズアップ',
  object: 'オブジェクト',
  watercolor: '水彩画',
  flat: '平面',
  cartoon: 'マンガ',
  geometric: '幾何学的',
  gradient: 'グラデーション',
  isometric: 'アイソメトリック',
  '3d': '3D',
  handDrawn: '手描き',
  natural: 'ナチュラル',
  warm: '暖かみ',
  dramatic: 'ドラマチック',
  vividAndBold: '鮮やか＆ダイナミック',
  blackAndWhite: '黒と白',
  // introduce Free image research
  imageResearchContentTitle: '123RFの無料画像リサーチサービス： 手間なく理想の画像を',
  imageResearchContentDesc:
    '123RFは法人顧客向け画像リサーチに革命を起こします。理想にぴったりの画像を簡単に見つけ、思い描いたイメージを実現しましょう。',
  submitRequest: 'リクエストを送信',
  imageResearchContentInfoTitle1: '画像リサーチをシンプルに',
  imageResearchContentInfoDesc1:
    '始めるのは至って簡単です。スタイル、ムード、色、具体的な要素など、希望する画像の詳細をお知らせください。より詳細な情報が、よりニーズに合ったサービスのご提供につながります。',
  imageResearchContentInfoTitle2: 'どのようなサービスですか？',
  imageResearchContentInfoTitle3: '迅速で、頼れるサービス',
  imageResearchContentInfoTitle4: '私たちにお任せください',
  imageResearchContentInfoDesc4:
    'お客様がプロジェクトに集中できるよう、画像探しは私たちが担当します。お客様の理想にぴったりの高品質な画像を厳選します。',
  requestForm: 'リクエストフォーム',
  whatDoYouWantThisImageFor: '何に使用する画像をお探しですか？',
  websiteBanner: 'ウェブサイトバナー',
  socialMedia: 'SNS',
  blogPost: 'ブログ記事',
  advertisement: '広告',
  print: '印刷物',
  others: 'その他',
  othersOutputPlaceholder: 'その他の用途を入力してください',
  whatIsPrimaryMessage: '画像で伝えたい主なメッセージやテーマは何ですか？',
  whatIsPrimaryMessagePlaceholder: '例：公園で女性と走る犬',
  areThereSubjectYouWantInThePicture: '画像に入れたい具体的な被写体や要素はありますか？',
  egPeopleNatureTechnologyAbstract: '例：人物、自然、テクノロジー、抽象的な概念',
  areThereImageOrStyleReference:
    'これまでに見た画像やスタイルで、取り入れたり参考にしたいものはありますか？',
  ifPeopleIncludeWhatTheirDemographic: '人物を含める場合、その人物の詳細を教えてください。',
  egAgeGenderEthnicityOccupation: '例：年齢、性別、民族性、職業',
  areThereAnyThingToAvoid: '画像の中で避けたい要素、主題、テーマはありますか？',
  whoisYourTargetAudience: 'どのような層をターゲットとしますか？',
  whoisYourTargetAudiencePlaceholder: '例：読書好きの若者',
  typeOfImage: '画像のタイプ',
  whatMoodShouldImageEvoke: '画像でどのようなムードや感情を伝えたいですか？',
  happy: 'ハッピー',
  sad: '悲しい',
  calm: '穏やか',
  excited: '興奮',
  angry: '怒り',
  enterCustomMood: 'オリジナルのムードを入力',
  areThereAnyOrientationForImage: '画像の向きを指定しますか？',
  enterCustomOrientation: 'オリジナルの向きを入力',
  isThereLocationYouWantImageToBeIn: '画像に入れたい特定の場所や設定はありますか？',
  no: 'いいえ',
  yes: 'はい',
  shareWithUsAReference: '参考資料を提供してください（JPGまたはPDFのみ、最大4MB）',
  replace: '置き換える',
  areThereAnySpecificRequirements: '他に特別なリクエストや要望はありますか？',
  enterOtherComments: 'その他のコメントを入力',
  submit: '送信',
  fieldCannotEmpty: 'このフィールドは入力必須です',
  selectAtLeastOne: '少なくとも1つを選択してください',
  fileMustBeJpgOrPdf: 'ファイルはJPGまたはPDFでなければなりません',
  fileSizeLessThan4MB: 'ファイルサイズは4MB以下でなければなりません',
  maximumFileNameAllow255: '最大文字数は255文字です',
  updateSuccessfully: 'リクエストを受け付けました。',
  freeImageResearch: '無料画像リサーチ',
  newService: '新しいサービスです！',
  freeImageResearchIntroduction:
    '123RFの画像リサーチサービスをぜひお試しください。お客様のリクエストにぴったりの画像をお探しいたします。',
  later: 'あとで',
  letTryIt: '試してみる！',
  viewPdfOfSampleOutput: 'サンプルアウトプットのPDFを見る',
  imageResearchContentInfoDesc2: (
    <>
      当社チームが
      <strong>AI技術</strong>
      と経験を組み合わせてお客様の理想に合った画像を探し出し、膨大なライブラリから高度なアルゴリズムがさらに選択肢を絞り込みます。当社の
      <strong>経験豊富な専門リサーチチーム</strong>
      が検索結果をさらに精査し、お客様のリクエストに最適な画像を厳選します。
    </>
  ),
  imageResearchContentInfoDesc3: (
    <>
      <strong>2営業日</strong>
      日以内にリサーチ結果と、選択された画像に対する説明をお届けします。結果をお待ちいただいている間に、お客様はビジネスに集中できます。
    </>
  ),
  characterMaxChar255: '最大文字数は255文字です',

  imageResearchContentInfoDesc2Normal1: 'Our team combines',
  imageResearchContentInfoDesc2Strong1: 'AI technology',
  imageResearchContentInfoDesc2Normal2:
    'and experience to find ideal images for you and advanced algorithms narrow down options from our vast library based on your input. Our',
  imageResearchContentInfoDesc2Strong2: 'team of dedicated and experienced researchers',
  imageResearchContentInfoDesc2Normal3: 'refine the results to meet your criteria perfectly.',
  imageResearchContentInfoDesc3Normal1: 'We deliver results within',
  imageResearchContentInfoDesc3Strong1: '1 working day',
  imageResearchContentInfoDesc3Normal2:
    'providing a comprehensive explanation for image selections. Focus on your business while we take care of the rest.',
  office: 'Office',
  beach: 'Beach',
  city: 'City',
  mountains: 'Mountains',
  enterCustomLocation: 'Enter custom location',
  doYouNeedImgWithSpaceForTextOrOtherElement:
    'Do you need an image with space for text or other elements? If so, where should this space be located?',
  center: 'Center',
  topLeft: 'Top left',
  topRight: 'Top Right',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  characterMin30: 'A minimum of 30 characters is required',
  required: 'を入力してください',

  relatedSearch: 'Related searches',
  showMore: 'もっと見る',
  showLess: '表示アイテムを減らす',

  requiredWithVariable: '「{{var}}」 を入力してください',
  mood: 'ムード',
  person: '人',
  doNotIncludeWords_ex2: '例：緑色、ストレートヘアー',

  // Text to Image TTI page
  /// Landing page
  aiImageGenerator: 'AI画像生成機能',
  aiImageGeneratorDesc: 'クリエイティブなアイディアを素早くお届けします。',
  generate: '生成する',
  exploreVisualsCreatedWithThePowerOfAI: 'AIの力で作成されたビジュアルを探索します',
  turnYourImaginationIntoRealityIn3SimpleSteps: '簡単な3ステップでイメージを現実に',
  startWithGoodTextPrompt: (
    <>
      明確なプロンプト
      {' '}
      <span>
        から
        <br />
        始める
      </span>
    </>
  ),
  startWithGoodTextPromptDesc:
    'より具体的なプロンプトが、より良い結果を生み出します。優れた生成画像を得るには、プロンプトに視点、主題、動作、キーワード、明るさ、スタイルなどを含めると良いでしょう。',
  reviewYourAIGeneratedImages: (
    <>
      AIで生成さ
      {' '}
      <br />
      <span>れた画像を見る</span>
    </>
  ),
  reviewYourAIGeneratedImagesDesc:
    '123RFのAI生成機能は、お客様のプロンプトに最も近い3つのプレビュー画像を生成して表示します。',
  downloadAndLicenseVisuals: (
    <>
      画像の
      {' '}
      <span>
        ダウンロードと
        <br />
        ライセンス
      </span>
    </>
  ),
  downloadAndLicenseVisualsDesc:
    'これらの画像をダウンロード、また個人および商用利用可能な25,000米ドルの法的補償付きライセンスを取得することができます。',
  needSomeIdeas: 'アイデアが必要ですか？まずサンプルから始めて、アイデアを生み出してください！',
  tryPrompt: 'プロンプトを試す',
  creativityAtTheSpeedOfYourImagination: 'クリエイティブ作品をスピーディーに',
  genYourOwnAIImgFromTxtPromptsWCorpInSeconds:
    'Corporate+を使えば、ほんの数秒でテキストプロンプトから自分だけのAI画像を生成できます！',
  tryNow: '今すぐ試す',

  /// Random prompt
  prompt1:
    '地球を背景に宇宙空間に浮かぶ宇宙飛行士のリアリティ溢れるポートレート。200mmのレンズを使用し、F1.2の絞り値で背景をぼかし、被写体を浮き上がらせる。ライティングは、宇宙飛行士の顔や宇宙服に太陽が当たるような、ドラマチックで夢のあるもの。フォトリアリズムモードをオンにして、宇宙の広大さと美しさをとらえた超リアルな画像を作成する。',
  prompt2:
    '神秘的で別世界のような風景を背景に、流れるようなマントと魔法の火花を散らす杖を持った魔法使いがドラマチックなポーズで登場するミックスメディア作品。',
  prompt3:
    '陽気なパステルカラーを背景に、バラ色の頬にふわふわのワンピを着た赤ちゃんが、カラフルな風船や紙吹雪に囲まれている、遊び心あふれるデジタルイラストです',
  prompt4:
    '額に第3の目を持つ盲目の予言者が、穏やかで神秘的な表情で遠くを見つめている様子を、複雑なディテールと陰影で描いたグラファイト画。',
  prompt5:
    '砂漠の荒地で巨大な機械仕掛けの牛に乗るカウボーイのシュールなミックスメディア・コラージュ。古雑誌、古地図、錆びた金属片で作られており、ユーモアと不条理を感じさせる。',
  prompt6:
    '青白い肌と血のような赤い瞳を持ち、流れるようなドレスを着てコウモリに囲まれているヴァンパイアの女王のデジタルイラスト。',
  prompt7:
    '水晶玉を持つ盲目の占い師のポートレート。ドラマチックな逆光照明が、占い師の顔の特徴と水晶の反射を際立たせる。',
  prompt8:
    '長い髪に貝殻の冠をかぶった人魚の女王が、生物発光する生き物でいっぱいの神秘的な海を泳ぐファンタジー・イラスト。',
  prompt9:
    '果物や野菜を売るヒスパニック系の露天商のストリート・ポートレート。露天商の表情を強調するために被写界深度を浅くした、率直で硬質なモノクロ写真。',
  prompt10: 'きらめく海の波と戯れる海の生き物に囲まれ、岩場でくつろぐ人魚の幻想的な水彩画。',
  prompt11:
    '3Dスタイルの少女、4k、8k、オクタンレンダーフォトリアリスティック、hdr、写真、高精細、左右対称の顔、ボリューム照明、埃っぽい霞、写真、オクタンレンダー、24mm、4k、24mm、デジタル一眼レフ、高画質、60 fps、超リアル',
  prompt12:
    '庭に面したフランス窓のあるリビングルーム、プロヴァンス風サイドボード、観葉植物、麻のカーペット、寄木細工、クッション付きソファ、クリスタルのシャンデリア、インテリア雑誌、暖かい雰囲気、写実的、リアルな光、広角、細部多数',
  prompt13:
    '山の斜面にある絵のような山小屋、大きなパノラマ窓、幾何学的な小屋、全景、黄昏時の景色、ドラマチックな黄昏空、雪の下の峰々、星、雪山の荒野、美しい景色、むら気、霧、ボリューム照明、山の雰囲気、フォトリアリズム、ボリューム照明、リアル、オクタンレンダー、超詳細、超現実的、フォトリアリスティック、超現実的、8k、映画のような照明',
  prompt14: 'クールなおばあちゃんの水彩画、自信に満ちた微笑みを浮かべている',
  prompt15: '紺碧の森の中の道、鬱蒼とした森、葉っぱの天井、夜、漫画風、子供向けのイラスト',
  prompt16:
    '宇宙で星雲の中を泳ぐ宇宙飛行士＋宇宙、宇宙飛行士、夢のような、象徴、シュール、シンボル、シュール、抽象的、テクスチャ、コンセプトアート、8K、影、光線、雰囲気',

  /// Tool list
  // ratio
  ratio: '比率',
  square1_1: '正方形 (1:1)',
  square: '正方形',
  portrait2_3: '縦長 (2:3)',
  portrait: '縦長',
  landscape3_2: '横長 (3:2)',
  wide16_9: 'ワイド (16:9)',
  wide: 'ワイド',
  // style
  none: 'なし',
  enhance: '強調',
  anime: 'アニメ',
  photographic: 'フォトグラフィック',
  digitalArt: 'デジタルアート',
  comicBook: 'コミック',
  fantasyArt: 'ファンタジーアート',
  analogFilm: 'アナログフィルム',
  neonPunk: 'ネオンパンク',
  lowPoly: 'ローポリ',
  origami: '折り紙',
  lineArt: '線画',
  tileTexture: 'タイルテクスチャ',
  threeDModel: '3Dモデル',
  pixelArt: 'ピクセルアート',
  craftClay: '粘土細工風',
  // color
  coolTone: 'クールトーン',
  mutedColors: '落ち着いた色',
  pastelColors: 'パステルカラー',
  vibrantColors: '鮮やかな色',
  warmTone: '暖色系',
  // lighting
  lighting: '照明',
  backlight: 'バックライト',
  crepuscularRays: '薄明かり',
  dimlyLit: '薄暗い照明',
  goldenHour: '夕暮れ時',
  lowLight: '微光',
  rimLighting: 'リム照明',
  studio: 'スタジオ',
  sunlight: '太陽光',
  volumetric: 'ボリュームライト',
  // composition
  composition: '構図',
  blurryBackground: '背景をぼかす',
  closeUp: 'クローズアップ',
  macrophotograph: 'マクロ撮影',
  narrowDepth: '狭い奥行き',
  shotFromAbove: '上から撮る',
  shotFromBelow: '下から撮影',
  wideAngle: '広角',

  /// FQAs
  allAIImageQuestionsAnswered: 'AI生成画像についての質問にお答えします。',
  TTI_Q1: 'AI生成画像とは何ですか？',
  TTI_A1:
    'AI生成画像は、人工知能（AI）アルゴリズムを使用して作成された画像です。これらのアルゴリズムは、人間の創造性をシミュレートするように設計されており、人間のアーティストが制作した画像に似ていますが、ユニークで超現実的、または抽象的な要素を持つ画像を生成することができます。',
  TTI_Q2: '1日に生成できる画像の枚数に制限はありますか？',
  TTI_A2:
    'はい、1日に生成できる画像数には制限があります。この制限は、すべてのユーザーにAI画像生成サービスへの公平なアクセスと、優れたユーザー体験を提供するためのものです。',
  TTI_Q3: 'Do you support text prompts in languages other than English?',
  TTI_A3: 'We support only the English language at the moment.',
  TTI_Q4: '画像の生成とライセンスの違いは何ですか？',
  TTI_A4:
    '画像を生成すると、閲覧用のAI画像セットが作成されます。コーポレートPLUSでライセンスを取得すると、独自の使用ライセンスが付いたAI画像の高解像度バージョンをダウンロードするための正式なアクセス権が付与されます。',
  TTI_Q5:
    '自分が作成した画像に、商標やランドマーク、人物などが含まれていることが判明しました。それでも使用できますか？',
  TTI_A5:
    '認識可能な人物や物体が写っている場合、あるいは特徴的な場所に主眼が置かれている場合、特に商業目的で使用する場合は、画像に写っている当事者から直接許可を得る必要があります。',
  TTI_Q6: '生成された画像に登場する人物は誰なのでしょうか？',
  TTI_A6: 'AIが生成した映像に登場する人物は、実在の人物ではなく、デジタルで作られたものです。',
  TTI_Q7: '自分が生成したコンテンツはどうなるのですか？他のユーザーにも公開されるのでしょうか？',
  TTI_A7:
    'お客様が生成したコンテンツは、他のユーザーにも提供される場合があります。詳細については、当社のライセンスを参照してください。',
  TTI_Q8: '画像を生成しようとすると、読み込みに時間がかかるのはなぜですか？',
  TTI_A8:
    '画像の生成には数秒かかります。プロンプトの複雑さ、利用可能なハードウェアとコンピューティングリソース、およびAIが同時に処理しなければならないリクエストの量によって所要時間は変わります。',
  TTI_Q9: '生成したAI画像のライセンスはどのように取得できますか？',
  TTI_A9:
    '作成したAI画像は、コーポレートPLUSアカウントからダウンロードすることで、ライセンスを取得することができます。ダウンロードするごとに、生成された画像の高解像度バージョンと25,000米ドルの法的保証をご利用いただけます。',
  TTI_Q10: '過去に生成したものを見ることはできますか？',
  TTI_A10:
    'はい、過去に生成した作品のURLをブックマークして再表示することができます。また、その作品を仲間と共有することもできます。',
  TTI_Q11: 'AIで高画質な画像を生成するにはどうすればよいですか？',
  TTI_A11: `1. 明確な目的を定める："旅行ブログ用の山の風景の高画質画像" など

2. 希望するスタイルとムードを指定する： "明るく鮮やかで、自然な雰囲気のある画像" など

3. 被写体について説明する：被写体について具体的に説明し、設定、ライティング、その他の重要な特徴や要素など、詳細について具体的に説明します。例えば、"雪をかぶった山頂と曲がりくねった川を前景に、澄んだ青空を背景に” など

4. 例を挙げる： 探している画像と似たようなスタイルや雰囲気を持つ他の画像の例を記載します。これは、AIアルゴリズムの指針となり、出力があなたの期待に応えることを保証します。

5. 関連するキーワードを使用する： "山の風景"、"雪をかぶった山頂"、"明るく鮮やか" など

6. 曖昧さを避ける： できるだけ明確で具体的な指示を出すことで、イメージとかけ離れた結果を避けられます。`,

  /// Generating page
  bringingYourImagesToLife: 'あなたのイメージに命を吹き込む...',
  cplusAiGenerator: 'AI画像生成',
  generatedImages: '生成された画像',
  promptSettings: 'プロンプトの設定',
  pastGeneration: '過去の生成作品を見る',
  generateVariation: 'バリエーションを生成する',
  iLikeThis: '好き',
  iDislikeThis: '嫌い',
  shareOptions: 'シェアオプション',
  variations: 'バリエーション',
  copiedSharedUrl: '共有URLをクリップボードにコピー',

  galleryPrompt1: '神秘的で控えめな人魚、水中、魚に囲まれた貝殻の冠、肖像画',
  galleryPrompt2: 'テーブルに置かれたボリュームたっぷりのサンデーロースト、食べ物とキャンドルで埋め尽くされている',
  galleryPrompt3: '森の中で微笑むかわいいふわふわのキツネ、非常に詳細、他の森の動物たちとズームアウトされている',
  galleryPrompt4: '高画質、8K Ultra HD、水晶でできたカボチャの中の音符の形をした楽器、高精細、魔法のカボチャのランプ、秋の雰囲気、落ち着き',
  galleryPrompt5: 'スコットランド、パノラマ映画のような風景、明るい美しい日',
  galleryPrompt6: '3Dスタイル少女、4k、8k、オクタンレンダリング、写実的、hdr、写真、高精細、左右対称の顔、ボリューム照明、埃っぽい霞、写真、オクタンレンダリング、24mm、4k、24mm、一眼レフ、高品質、60 fps、超リアル',
  galleryPrompt7: '褐色の肌の男、宇宙で木星を殴るヒーロー、正面を向いている',
  galleryPrompt8: '暖かいコーヒーに入ったクッキー、クリスマスの雰囲気の中で',
  galleryPrompt9: '白いミニマリストの背景にモンセラの葉をあしらったアーティスティックな水筒',
  galleryPrompt10: 'エバネッセント・ダイヤモンド・ファンタジー・ドラゴン',
  galleryPrompt11: '露の惑星、惑星の露、日の出、クローズアップ、超詳細、シャープフォーカス、スタジオ写真、複雑なディテール、極めて詳細な描写',
  galleryPrompt12: '金のチェストの上に座るカササギ、リアルなファンタジー作品風、宝石、ダークメルヘン、ミステリアス',
  galleryPrompt13: '雪山、山頂の氷の軌跡、ウルトラHD、リアル、鮮やかな色彩、非常に詳細、UHDドローイング、ペンとインク、完璧な構図、美しく詳細な入り組んだ、アートステーションで流行りのオクタンレンダー、8K芸術写真、写実的なコンセプトアート、ソフトで自然なボリューム感、映画のような完璧な光',
  galleryPrompt14: '恒星間文明時代、神秘的なデジタル空間スーパーSFエイリアン都市ビルを見下ろす高さに立って、絶妙な美しい外観超高層タワービル、通り、スカイブリッジ、空に浮かぶスーパーSF建築、戦争マシン、ハイテク、 無数の電子スクリーンライト、超SF映像の饗宴、宇宙、SF、ミステリー、SF、超現実的、超クリアな写真、HDピクセル、超HD、5D、8K解像度、ピクセルパーフェクト、完璧な超微細ディテール、シャープフォーカス、スタジオ写真、複雑なディテール、非常に詳細な描写',
  galleryPrompt15: '夜のフロリダキーズの海を背景にした美しいビーチ、ネオンカラー、黒い背景、穏やかな波、自然な色合い、日没、穏やか、波の中でリラックスしたズーム、ビーチ、水平線をまっすぐに、ピンク、青、オレンジ、紫',
  galleryPrompt16: '最高品質、二重露光、メールアート、ひび割れた紙に描かれたアート、羊毛のボールで遊ぶクローズアップのかわいい子猫、詳細な居心地の良い部屋のインテリア、きらめき、日光、太陽光線、ダイナミックなポーズ、絵本のイラスト、2D、フラット、かわいい、愛らしい、ヴィンテージ、おとぎ話、パッチワーク、ステンドグラス、絵本の詳細なイラスト、映画のような、超高詳細、小さなディテール、美しいディテール、神秘的な、鮮やかな色、複雑な背景',
  galleryPrompt17:
    '火の反射する水上のピラミッド、山、ダークシュルレアリスム、雰囲気のあるポートレート、シャープフォーカス',
  galleryPrompt18: '森の中のレッサーパンダ、シネマティック',
  galleryPrompt19: '海、月、魚、星空、ボート、カラフルなイラストレーション',
  galleryPrompt20:
    'ミニマリストの抽象的なスタイル、大胆な建物ときらめく水の中で踊る活気に満ちた都市の反射、生き生きとした夜のエネルギーをとらえた幾何学的抽象画、ネオン照明とアクリル絵の具の技法を使った現代的なポップアートスタイル',
  galleryPrompt21:
    '輝く剣を持ったシルエットの女性の後ろ姿、氷山へと続く北極の円形ポータル、映画のようなミディアムショット、記念碑的な人物',

  /// error page
  copyrightDetected: '著作権で保護された用語 ／ 人物または商標が検出されました',
  copyrightDetectedDesc:
    '注意 商標、著作権、有名なランドマークや個人に関連するキーワードのプロンプトでの使用は制限されています。疑問がある場合は、進める前に法的助言を求めてください。プロンプト（画像を生成するために提供された説明）を修正し、再度お試しください。',
  errorMesTTI: '申し訳ありません、何か問題が発生したようです。',
  errorMesTTIDesc: 'この問題は調査のために記録されています。後でもう一度お試しください。',
  tryAgain: 'もう一度試す',
  // hruc bulk download
  hrucBulkDownloadTitle: 'HRUC bulk download',
  hrucDownload: 'HRUC download',
  hrucBulkdownloadNotSupported: 'HRUC bulk download is not supported',
  // download feature
  downloadQuotaFinished:
    'You have reached the maximum quota available. Please contact your system administrator.',

  // search bar placeholder
  searchBarPlaceholderAllImageDesktop: '画像を探す。キーワードまたはフレーズで検索。',
  searchBarPlaceholderAllImageTablet: '画像を探す。',
  searchBarPlaceholderAllImageMobile: '画像を探す。',
  searchBarPlaceholderPhotoDesktop: '写真素材を探す。キーワードまたはフレーズで検索。',
  searchBarPlaceholderPhotoTablet: '写真素材を探す。',
  searchBarPlaceholderPhotoMobile: '写真素材を探す。',
  searchBarPlaceholderVectorDesktop: 'イラスト素材を探す。キーワードまたはフレーズで検索。',
  searchBarPlaceholderVectorTablet: 'イラスト素材を探す。',
  searchBarPlaceholderVectorMobile: 'イラスト素材を探す。',
  searchBarPlaceholderFootageDesktop: '動画素材を探す。キーワードまたはフレーズで検索。',
  searchBarPlaceholderFootageTablet: '動画素材を探す。',
  searchBarPlaceholderFootageMobile: '動画素材を探す。',
  searchBarPlaceholderAudioDesktop:
    'オーディオまたは音楽ファイルを探す。キーワードまたはフレーズで検索。',
  searchBarPlaceholderAudioTablet: 'オーディオまたは音楽ファイルを探す。',
  searchBarPlaceholderAudioMobile: 'オーディオまたは音楽ファイルを探す。',
  searchBarPlaceholderFreeDesktop: '無料画像を探す。キーワードまたはフレーズで検索。',
  searchBarPlaceholderFreeTablet: '無料画像を探す。',
  searchBarPlaceholderFreeMobile: '無料画像を探す。',
  // editorial warning
  forEditorialUseOnly: 'エディトリアル使用のみ',
  forEditorialUseOnlyDesc:
    '広告やプロモーション目的でこの画像を利用することは出来ません。詳細はお問い合わせください。',

  // download comp
  downloadHiResComp: '高解像度カンプダウンロード',
  downloadComp: 'カンプダウンロード',

  generateTerm: ({ callback }) => (
    <>
      「生成する」をクリックすることで、生成された成果物は
      <span aria-hidden onClick={callback}>
        AI生成利用規約
      </span>
      に同意したものとみなされます。
    </>
  ),

  textToImage: 'テキストから画像へ',
  descTourTTI: '想像力を膨らませ、テキストで画像を作成しましょう！',
  colorAndTone: '色とトーン',
  titleSuccessDownload: 'お探しのものが見つかってよかったです。',
  hangInThere: 'がんばりましょう！もうすぐです。',
  adultContentTitle: '潜在的なアダルトコンテンツ用語が検出されました。',
  adultContentDesc:
    'アダルトコンテンツに関連する用語が検出されました。123RFでは、安心してご利用いただけるAI生成に取り組んでいるため、特定のフレーズやコンセプトに関する結果を生成しません。プロンプト(画像を生成するために提供した説明）を修正し、再度お試しください。',
  reachedTheLimit: '今日のAI画像生成制限に達してしまいました。',
  backTomorrow: '明日以降再度お試しください！',
  generateTermService: 'AI Generator Terms of Service',
  endUserLicenseAgreement: 'End user license agreement',
  aiPromptPlaceholder: 'まずはあなたのイメージをお聞かせください。123RFがそれを実現させます！',
  price: '金額',
  JPY: '円-税抜',

  downloadAgain: 'Download again',
  btnClose: '閉じる',

  variousTitle: '様々なモデルをご覧ください',
  variousSDXL: 'リアリティあふれる人物やシーンに命を吹き込みましょう。',
  variousEimisAnime: 'アニメスタイルの画像作成に特化したAI生成モデル。',
  variousDynaVisionXL: '3Dスタイル化された漫画風キャラクターを生成。',
  variousJuggernautXL: '建物のエレメントと風景/背景を中心に構成されています。',
  variousDreamShaperXL: '高解像度アップスケール、進化したキャラクター生成、輪郭ボケの解消。',
  variousRealismEngineSDXL:
    'このモデルを使えば、繊細でリアリティ溢れる画像を作成することができます。',
  variousDALLE: 'DALL·E 3は、コンセプトからビジュアルへの変換を得意とする万能モデルです。',
  aiEngine: 'AIエンジン',
  'DALL-E': 'DALL-E 3',

  langText: 'テキスト',
  langElements: 'エレメント',
  langAuto: '自動',
  langBgRemoval: '背景除去',
  resetAll: 'すべてリセット',
  facebookImage: 'Facebook画像',
  facebookLink: 'Facebookリンク',
  facebookCover: 'Facebookカバー',
  facebookStory: 'Facebookストーリー',
  instagramPost: 'Instagram投稿',
  instagramStory: 'Instagramストーリー',
  twitterPost: 'Twitter投稿',
  twitterHeader: 'Twitterヘッダー',
  pinterestPost: 'Pinterest投稿',
  emailHeader: 'メールヘッダー',
  presentation: 'プレゼンテーション',
  presentationWide: 'プレゼンテーション（ワイド）',
  ebookCover: '電子書籍カバー',
  linkedinPost: 'LinkedIn投稿',
  brochureCover: 'パンフレットカバー（A4）',
  postcard: 'ポストカード',
  photoPrint: '写真プリント',
  youtubeProfileImage: 'YouTubeプロフィール画像',
  youtubeChannelCoverPhoto: 'YouTubeチャンネルカバー画像',
  langApplyBgRemoval: '背景除去を適用する',
  autoBnW: '自動白黒',
  autoAdjust: '自動調整',
  autoPop: '自動ポップ',
  original: 'オリジナル',
  addHeader: 'ヘッダーを追加',
  addTitle: 'タイトルを追加',
  addBody: '本文を追加',
  addAssistive: '補助テキストを追加',
  langCompare: '比較',
  langEnableEditor: '編集する',
  langSimilarStock: '類似のストック画像',
  langTextUndo: '元に戻す',
  langTextRedo: 'やり直し',
  langTextFont: 'フォント',
  langTextFontSize: 'フォントサイズ',
  langTextColor: 'テキストカラー',
  langTextHighlight: 'ハイライト',
  langTextAlignment: '行揃え',
  langTextBold: '太字',
  langTextItalic: '斜体',
  langTextUnderline: '下線',
  langTextLineSpacing: '行間',
  langTextLetterSpacing: '文字間隔',
  langBringForward: '前面へ移動',
  langBringBackward: '背面へ移動',
  langDuplicate: '複製',
  langOpacity: '不透明度',
  langDelete: '削除',
  imageProperties: '画像プロパティ',
  noEditCompareMsg: '比較対象となる',
  stockPhoto: '写真素材',
  basicShape: '基本図形',
  chitChat: '吹き出し',
  shapes: '図形',
  elementChitChat: '吹き出しエレメント',
  organicAbstractShapes: '抽象図形',
  elementShapes: '図形エレメント',
  langApplyingEdit: '編集を適用中です…',
  langTextAllFonts: 'すべてのフォント',
  langSearchFontType: 'フォントを検索',
  editorCompareImageBeforeTitle: '使用前',
  editorCompareImageAfterTitle: '使用後',
  langSizePx:
    'ピクセルとはデジタル画像の基本単位です。ピクセル単位で測定される画像サイズによって、詳細レベルとダウンロードサイズが決まります。',
  langSizeDpi:
    '1インチあたりのドット数で、印刷解像度を表します。dpiが高いほど、詳細度が高くなります。印刷の場合、画像の推奨解像度は300dpiです。',
  cautionTTI:
    'Caution : We detected the use of phrases in the prompt that are associated with trademarks, copyrights, famous landmarks and/or individuals.  Do note that You are solely responsible for the usage of the Content, including that it does not violate any applicable law or infringe any rights.',

  addImageReference: '参照画像の追加（任意）',
  uploadImageOnly: 'JPGまたはPNGのみをアップロードしてください。最大サイズは4MBです。',
  dragAndDrop: 'ファイルをドラッグ＆ドロップするか、',
  errorFileSize: 'ファイルが大きすぎます。 最大サイズは4MBです。',
  adjustScale: 'このスケールを調整することで、アップロードした画像とよく似た、または異なる画像を生成することができます。推奨範囲は7～15です。',
  similar: '似ている',
  different: '異なる',
  uploadNew: '新しい画像をアップロードする',

  imageGuideGeneration: 'アップロードした画像は、画像生成時にプロンプトのガイドになります。',
  notAvailableForEngine: '選択したAIエンジンは利用できません。',

  imagePromptDescribe: '頭に思い浮かんだ描写/説明文を、AI生成したい画像のプロンプトとして入力します。',
  fileTypeNotSp: 'ファイルタイプがサポートされていません。',
  theFileSizeTooSmall: 'ファイルサイズを満たしていません。最小アップロード可能サイズは512pxです。',

  relatedSearchPhoto: 'ストックフォト関連検索ワード',
  STOCK_PHOTO_PATH: 'ストックフォト',
  STOCK_FOOTAGE_PATH: 'stock-footage',
  STOCK_AUDIO_PATH: 'stock-audio',
  CLIPART_VECTOR_PATH: 'ベクター',
  ALL_IMAGES: 'すべての画像',
  PHOTO: 'Photo',
  PHOTOS: '写真素材',
  VECTORS: 'イラスト素材',
  FOOTAGE: '動画素材',
  AUDIO: '音楽素材',
  LANG_MORETHANMILLION: '点のロイヤリティフリー写真・イラスト・動画・音源素材',
  LANG_SVG_OR_EPS: 'SVG もしくは EPS',
  LANG_OR: 'もしくは',
  STANDARD_LICENSE: 'スタンダードライセンス',
  EXTENDED_LICENSE: '特別ライセンス',
  CREDIT_PACK: 'チケットパック',
  DOWNLOAD_PACK: 'イメージパック',
  SUBSCRIPTION_PLAN: '定額料金パッケージ',
  LANG_DOWNLOAD: 'ダウンロード',
  STOCKPHOTO: '写真素材',
  STOCK_PHOTO: 'ストック写真',
  STOCK_VECTOR: 'ストックベクター',
  PREMIUM_STOCKPHOTO: 'PREMIUM ストックフォト',
  PLUS_STOCK_PHOTO: 'PLUS ストックフォト',
  PLUS_STOCK_VECTOR: 'PLUS ストックベクター',
  MOBILE_STOCKPHOTO: 'モバイルストックフォト',
  STOCKFOOTAGE: '動画素材',
  IMAGEID: 'イメージID',
  FONTID: 'ID',
  MEDIATYPE: '画像の種類',
  COPYRIGHT_TEXT: '版権',
  KEYWORDS: 'キーワード',
  LANG_CANCEL: 'キャンセル',
  LANG_PURCHASE: 'ご購入',
  LANG_STOCKVECTORSANDILLUS: 'ストックベクター、イラストレーション',
  LANG_STOCKPHOTOSANDIMAGES: 'の写真素材とイメージ',
  LANG_MATCHES: '一致 ',
  LANG_VECTORRESULTS: '{number}件 - 「{keyword}」のロイヤリティフリーベクター画像',
  LANG_PHOTORESULTS: '{number}件 - 「{keyword}」のロイヤリティフリーストックフォト＆画像',
  LANG_SIMILARIMGS: '類似ロイヤリティフリー写真',
  LANG_COLLECTIONS: 'コレクション',
  LANG_ALL: 'すべて',
  LANG_STD: 'スタンダード',
  LANG_EXTD: '特別',
  LANG_COMMERCIAL: '商用利用',
  LANG_SUBSCRIPTION: '定額料金パッケージ',
  LANG_EDITORIAL: '報道画像',
  LANG_UPLOADED: 'Uploaded',
  LANG_FRESHNESS: '期間',
  LANG_ANYTIME: '期間指定なし',
  LANG_2DAYS: '2 days',
  LANG_PAST2DAYS: '過去2日間',
  LANG_PASTWEEK: '1週間以内',
  LANG_PRICE_PLUS_MONTH: "<span class='price'>{price}</span>/月",
  LANG_PRICE_PLUS_YEAR: "<span class='price'>{price}</span>/年",
  LANG_PASTMONTH: '1か月以内',
  LANG_PAST3MONTHS: '3か月以内',
  LANG_NUMOFPPL: '人数',
  LANG_NOT_RECEIVE_VERIFICATION_EMAIL: 'メールが届きませんでしたか？',
  LANG_WITHOUTPEOPLE: '人物を含まない',
  LANG_PERSON: '人',
  LANG_PEOPLE: '人',
  LANG_INFANTS: '乳幼児',
  LANG_TODDLERS: 'Toddlers',
  LANG_TEENS: '10代',
  LANG_20S: '20s',
  LANG_30S: '30s',
  LANG_40S: '40s',
  LANG_50S: '50s',
  LANG_60S: '60s',
  LANG_70S: '70 +',
  LANG_CAUCASIAN: '白人',
  LANG_BLACK: '黒人',
  LANG_HISPANIC: 'ヒスパニック系',
  LANG_EAST_ASIAN: '東アジア系',
  LANG_SOUTHEAST_ASIAN: '東南アジア系',
  LANG_INDIAN: 'Indian',
  LANG_MIDDLE_EASTERN: '中東系',
  LANG_AGE: '年齢',
  LANG_ALL_SHAPE: 'すべて見る',
  LANG_ETHNICITY: '人種・民族',
  LANG_PEOPLEORMORE: '4人以上',
  LANG_COLOR: 'カラー',
  LANG_OTHEROPTION: 'その他のオプション',
  SOLID_OR_TRANSPARENT_BACKGROUNDS: '単色/透過背景',
  LANG_FILTER_BY: '検索条件',
  LANG_FILTER: 'フィルタ',
  LANG_PHOTOGRAPHY: '写真',
  LANG_VECTORILLUST: 'ベクターイラストレーション',
  LANG_SORTBY: '並び順',
  LANG_MORE: 'その他',
  LANG_RELEVANCE: '関連度順',
  LANG_RESEND: '再送信',
  LANG_RESEND_VERIFICATION: '確認メールを再送する',
  LANG_NEWEST: '最新画像',
  LANG_GEORANK: 'Georank',
  LANG_ORIENTATION: '向き',
  LANG_HORIZONTAL: '横長',
  LANG_PORTRAIT: 'ポートレート',
  LANG_SQUARE: '正方形',
  LANG_PANORAMA: 'パノラマ',
  LANG_SELECTIVE_FOCUS: 'セレクティブ　フォーカス',
  LANG_PATTERN: 'パターン',
  LANG_VIBRANCE: 'ビブランス',
  LANG_VERIFICATION_DESCRIPTION: '123RFからコンテンツをダウンロードするには、アカウントの認証が必要があります。メールを確認するか、下記のリンクをクリックしてください。',
  LANG_VERIFICATION_TITLE: 'メールを確認してください',
  VERIFICATIONRESETUNABLE: 'メールを送信できませんでした。までご連絡ください',
  LANG_CHOOSEUPTO3COLORS: '3色までお選びいただけます',
  LANG_ISOLATED: '無背景',
  LANG_STYLE: 'スタイル',
  LANG_SAFESEARCH: 'セーフサーチ',
  LANG_ONLY_AUTHENTIC: 'オーセンティック限定',
  LANG_EXCLUDE_PLACEHOLDER: 'ex. cheese',
  LANG_EXCLUDEWORDS: '含めない言葉',
  LANG_EXCLUDE: '含まない',
  LANG_INCLUDE_AICONTENT: 'AIが生成したコンテンツを含む',
  VISUAL_SEARCH: 'ビジュアル検索',
  VISUAL_SEARCH_TOOLTIP_TITLE: '画像内の検索をカスタマイズする3つの簡単な方法！',
  VISUAL_SEARCH_TOOLTIP_INFO_1: '1.検索するアイテムを識別するために画像の点をクリックしてください。',
  VISUAL_SEARCH_TOOLTIP_INFO_2: '2.切り取り機能ボックスをドラッグして、ズームインまたは検索範囲を縮小します。',
  VISUAL_SEARCH_TOOLTIP_INFO_3: '3.「X」をクリックして検索に戻ります',
  VISUAL_SEARCH_NO_RESULTS: '検索結果がありません。',
  LANG_RIS_TITLE: '逆画像検索',
  LANG_RIS_NOTFOUND: '検索結果が見つかりません！',
  LANG_RIS_ERROR_HEADER: 'アップロードされた画像にエラーが含まれています。画像が以下の要件を満たしていることを確認してください：',
  LANG_RIS_REFINE_SRCH_MSG: 'キーワードで絞り込む',
  LANG_RIS_UPLOADED_IMAGE: 'アップロードした画像',
  LANG_TELL_ME_MORE: 'もっと詳しく',
  LANG_ENTERKEYWORD: 'キーワードを入力してください',
  LANG_SIZE: 'サイズ',
  LANG_STANDARD_SIZE: '通常サイズ',
  LANG_EXTENDED_SIZE: '拡張サイズ',
  LANG_SIZE_PX: 'ピクセルとはデジタル画像の基本単位です。ピクセル単位で測定される画像サイズによって、詳細レベルとダウンロードサイズが決まります。',
  LANG_SIZE_DPI: '1インチあたりのドット数で、印刷解像度を表します。dpiが高いほど、詳細度が高くなります。印刷の場合、画像の推奨解像度は300dpiです。',
  LANG_SOCMED_SIZE: 'ソーシャルメディアサイズ',
  LANG_COMPARE: '比較',
  LANG_AUTO_ENHANCE: 'オートエンハンス',
  LANG_AUTO: '自動',
  LANG_BG_REMOVAL: '背景除去',
  LANG_TEXT: 'テキスト',
  LANG_ELEMENTS: 'エレメント',
  LANG_IMAGE_INFO: '画像情報',
  LANG_FOLLOW: 'フォローする',
  LANG_FOLLOWED: 'フォロー中',
  LANG_FOLLOWING: 'フォロー中',
  FACEBOOK_IMAGE: 'Facebook画像',
  FACEBOOK_LINK: 'Facebookリンク',
  FACEBOOK_COVER: 'Facebookカバー',
  FACEBOOK_STORY: 'Facebookストーリー',
  INSTAGRAM_POST: 'Instagram投稿',
  INSTAGRAM_STORY: 'Instagramストーリー',
  TWITTER_POST: 'Twitter投稿',
  TWITTER_HEADER: 'Twitterヘッダー',
  PINTEREST_POST: 'Pinterest投稿',
  EMAIL_HEADER: 'メールヘッダー',
  PRESENTATION: 'プレゼンテーション',
  PRESENTATION_WIDE: 'プレゼンテーション（ワイド）',
  EBOOK_COVER: '電子書籍カバー',
  LINKEDIN_POST: 'LinkedIn投稿',
  BROCHURE_COVER: 'パンフレットカバー（A4）',
  POSTCARD: 'ポストカード',
  PHOTO_PRINT: '写真プリント',
  YOUTUBE_PROFILE_IMAGE: 'YouTubeプロフィール画像',
  YOUTUBE_CHANNEL_COVER_PHOTO: 'YouTubeチャンネルカバー画像',
  AUTO_COLOR: 'オートカラー',
  BALANCE: 'バランス',
  AUTO_LEVEL: 'オートレベル',
  AUTO_TONE: 'オートトーン',
  ENRICH: 'エンリッチ',
  RESET: 'リセット',
  RESET_ALL: 'すべてリセット',
  FILE_DOWNLOAD_OPTIONS: 'ファイルダウンロードオプション',
  FILE_DOWNLOAD_INITIATED: '新たにファイルをダウンロードする',
  IMAGE_PROPERTIES: '画像プロパティ',
  DOWNLOAD_DETAILS: 'ダウンロードプロパティ',
  FILE_SIZE: 'ファイルサイズ',
  LICENSE: 'ライセンス',
  FILEFORMAT: 'ファイルタイプ',
  CHANGE: '変更',
  PRICING: '料金',
  FIND_QUALITY_IMAGE: 'Find quality images now',
  SAVE_AS: '保存形式',
  IMAGE_USAGE: '利用用途',
  PRINT: 'プリント',
  ELECTRONIC: 'エレクトロニック',
  COMPREHENSIVE: '包括',
  DOWNLOAD_CREDITS: 'お手持ちのチケットパックから<b>{quota}チケット使用する</b>',
  BASIC_SHAPE: '基本図形',
  CHIT_CHAT: '吹き出し',
  SHAPES: '図形',
  ELEMENT_CHIT_CHAT: '吹き出しエレメント',
  ORGANIC_ABSTRACT_SHAPES: '抽象図形',
  ELEMENT_SHAPES: '図形エレメント',
  BUY_SINGLE_IMAGE: '1枚だけ購入<br/><b>{quota} チケット</b>',
  BUY_CREDIT_PACK: 'チケットパッケージ購入<br/><b>単品購入よりお得です</b>',
  BUY_SINGLE_IMAGE_CREDIT: 'チケットパックを購入 <br/><b>{quota} チケット</b>',
  BUY_CREDIT_PACK_POINT_1: '{quota}チケットが<b>{price}</b>',
  BUY_CREDIT_PACK_POINT_2: '毎月のご契約に必要はありません。    ',
  BUY_CREDIT_PACK_POINT_3: '有効期限は1年間です。',
  BUY_SINGLE_IMAGE_PSUB: '1枚だけ購入<br/><b>{quota}ダウンロードスロット</b>',
  BUY_DOWNLOAD_PACK: 'イメージパックを購入して<br/><b>更にお得</b>',
  BUY_DOWNLOAD_PACK_POINT_1: '{quota}画像のイメージパックは <b>{price}</b>',
  BUY_DOWNLOAD_PACK_POINT_2: '毎月のご契約に必要はありません。    ',
  BUY_DOWNLOAD_PACK_POINT_3: '有効期限は1年間です。',
  LOWEST_PRICE: '<b>もっともお得な</b><br/>定額料金パッケージ',
  LOWEST_PRICE_POINT_1: '1か月プラン<b>{price}</b>円です',
  LOWEST_PRICE_POINT_2: '画像・ベクターどのサイズでも10画像ダウンロード',
  LOWEST_PRICE_POINT_3: '1日のダウンロード上限なし, 残った画像数は翌月に繰り越し',
  PER_IMAGE_SIZE: '<b>{size}</b>サイズ単価',
  PER_IMAGE_ANY: '<b>すべてのサイズの</b>単価',
  PER_IMAGE: '単価',
  VIEW_PRICING: '他のプラントと料金',
  DOWNLOAD_BEGIN: 'すぐにダウンロードが始まります、少々お待ちください。',
  DOWNLOAD_NOT_STARTING: 'ダウンロードが始まりませんか?',
  SELECT_MIRROR: '[選択してください。]',
  SHOW_MORE: 'もっと見る',
  SHOW_LESS: '表示アイテムを減らす',
  LICENSE_TYPE: 'ライセンスタイプ',
  LICENSE_SUMMARY: 'ライセンス概要',
  BACK: '戻る',
  LANG_VECTOR: 'ベクター画像',
  PREMIUM_VECTOR: 'PREMIUM ストックベクター',
  SCALE_ANY_SIZE: '（サイズ変更可能）',
  LANDINGPAGE_VECTOR_HEADLINE: 'イラスト・ベクター素材 - 123RF',
  LANDINGPAGE_PHOTO_HEADLINE: '写真素材・ロイヤリティフリーストックフォト - 123RF',
  CATEGORIES: 'をカテゴリーで検索',
  LANDINGPAGE_PRICING_1: 'チケットパッケージ (1年間有効)',
  LANDINGPAGE_PRICING_2: '定額パッケージ購入',
  LANDINGPAGE_AS_LOW_AS: '1画像',
  EMPTY_SEARCH_TITLE: '検索結果が見つかりませんでした。',
  LANG_SMALL: 'S',
  LANG_MEDIUM: 'M',
  LANG_LARGE: 'L',
  LANG_EXTRA_LARGE: 'XL',
  LANG_LOW_CREDITS: 'ダウンロードチケットが不足しているようです。',
  LANG_TOPUP: '追加購入',
  LANG_TOPUP_NOW: '追加購入はこちら',
  SEO_DETAILS_TITLE_STOCKPHOTO: '{description}の写真素材・画像素材 Image {mediaId}',
  SEO_DETAILS_TITLE_CLIPARTVECTOR: '{description}のイラスト素材・ベクター Image {mediaId}',
  SEO_DETAILS_METADESC_STOCKPHOTO: '{description}の写真素材をダウンロード。低価格でご購入いただけます。Image {mediaId}',
  SEO_DETAILS_METADESC_CLIPARTVECTOR: '{description}のイラスト素材をダウンロード。低価格でご購入いただけます。Image {mediaId}',
  SEO_DETAILS_TITLE_FREE_PHOTO: '{description} 無料画像＆写真 {mediaId}',
  SEO_DETAILS_METADESC_FREE_PHOTO: '{description}の無料写真。無料画像 {mediaId}',
  SEO_DETAILS_TITLE_FREE_VECTOR: '{description} 無料ベクター＆イラスト {mediaId}',
  SEO_DETAILS_METADESC_FREE_VECTOR: '{description}の無料ベクター。無料イラスト {mediaId}',
  SEO_DETAILS_OGDESCRIPTION: '123RF－ストックフォト、ベクター、動画、オーディオ、1億点以上のクリエイティブ素材をひらめきやプロジェクトに。',
  LIVECHAT_LOOKINGHELP: 'お手伝いが必要ですか？',
  LIVECHAT_CHATWITHUS: 'ライブチャットで問い合わせる',
  FREE_TRIAL: '<b>Get 10 Free Images</b> <br/>with Subscription Plan',
  LANG_RELATEDSEARCH: '他のキーワード',
  LANG_RELATED_STOCKPHOTO_SEARCH: 'ストックフォト関連検索ワード',
  LANG_PROPERATTRIBUTE: 'クレジット表記はこちらです：',
  LANG_ATTRIBUTEDESC: 'お客様の製品やウェブサイトなど任意の場所にこのクレジットラインを配置し、クレジット表記を行ってください。',
  LANG_ATTRIBUTE_TITLE: 'クレジット表記は任意です：',
  LANG_ATTRIBUTE_CREDIT: '作者のクレジットを表記したい場合は、下記のクレジットラインをご利用ください。',
  LANG_ATTRIBUTE_COPYLINK: 'リンクをコピー',
  LANG_ATTRIBUTE_COPYTEXT: 'テキストをコピー',
  LANG_LICENSE_AGREEMENT: 'ダウンロードの使用許諾契約書は<a href={url}><b>こちら</b></a>です。',
  LANG_NEXTPAGE: '次のページ',
  LANG_IMAGE_NO_AVAILABLE: 'イメージID #{mediaId} はご利用いただけません。',
  LANG_PHOTOGRAPHY_METAIMG_DESC: '123RFでは高品質なロイヤリティフリー写真素材・ストックフォト画像素材を低価格でご購入可能。3000万枚以上の素材点数、毎日3.5万点ものフリー素材をライブラリーにご用意。1枚75円から購入可能。無料素材も毎週更新。',
  LANG_VECTOR_METAIMG_DESC: '123RFでは高品質なロイヤリティフリーイラスト素材・ベクター画像素材を低価格でご購入可能。3000万枚以上の素材点数、毎日3.5万点ものフリー素材をライブラリーにご用意。1枚75円から購入可能。無料素材も毎週更新。',
  LANG_ANOTHERMETAKWDEFAULT: '写真素材, ロイヤリティフリー写真, ロイヤリティフリー画像イラスト素材、ベクター素材、ベクター画像、ロイヤリティフリー、ストックフォト、フリー素材、フリー画像、イメージ写真, ロイヤリティフリーストックフォト、ストックフォト、無料画像、高解像度, ロゴ',
  LANG_ANOTHERMETADESCDEFAULT: '写真素材・イラスト・動画・オーディオ販売の１２３RFでは、高品質なロイヤリティフリー画像素材を1枚21円から低価格でご購入可能。3000万枚以上の素材点数、毎日3.5万点もの素材を追加。法人プランや、予算に応じて購入できるプランもご用意。無料素材も毎週更新。',
  EMPTY_FILTERED_RESULT_TITLE: '申し訳ございません。一致する検索結果を得られませんでした。',
  EMPTY_FILTERED_RESULT_SUBTITLE: 'フィルターを減らすか別のキーワードでお試しください。',
  CLEAR_ALL_FILTER: 'フィルターをクリア',
  PROMOBAR_FREE10_TEXT: 'Get <b>10 FREE Images</b> when you get started on our 1 Month-Free Trial.',
  PROMOBAR_FREE10_BTNLABEL: 'START NOW',
  PROMOBAR_MASSIVE_MARKDOWN_TEXT: '全定額料金パッケージが大幅値引き',
  PROMOBAR_MASSIVE_MARKDOWN_BTNLABEL: 'プランを見る',
  LANG_MODEL_RELEASED: 'モデルリリース',
  LANG_PROPERTY_RELEASED: '建築物リリース',
  LANG_MODEL_RELEASE: 'モデルリリースあり',
  LANG_PROPERTY_RELEASE: 'プロパティリリースあり',
  LANG_YES: 'はい',
  LANG_NO: 'いいえ',
  LANG_MREXPLAIN: 'モデルリリースとは、写真の被写体であるモデルが、さまざまな形の写真の出版・配布・使用に対して同意をした法的書類です。',
  LANG_PREXPLAIN: 'プロパティリリースとは、写真が撮影された被写体・題材・場所のオーナーが、さまざまな形の写真の出版・配布・使用に対して同意をした法的書類です。',
  LANG_DOWNLOADS: 'ダウンロード',
  LANG_OLDFILENAME: '旧ファイル名',
  LANG_DOWNLOAD_PREVIEW: 'プレビューをダウンロード',
  LANG_SHARE: 'シェア',
  LANG_RESTRICTIONS: 'Restrictions',
  LANG_READ_MORE: 'こちらをご覧ください。',
  LANG_EDITORIAL_USE: 'For Editorial Use Only',
  LANG_EDITORIALDESCP: 'エディトリアル利用限定：こちらのイメージはエディトリアルでのご利用目的にのみお使いいただけます。広告や宣伝、プロモーションの目的にはご利用いただけません。弊社では使用許可の取得のサービスは行っておりません。',
  LANG_LOGO_OWNERSHIP: 'Logo Ownership Rights',
  LANG_PHOTO_SHOT_SMARTPHONE: 'This photo was shot with a smartphone.',
  LANG_VECTORNOLOGO: 'If you wish to use this as your company logo, we may arrange for a <b>rights buy out</b> on your behalf. Please contact us at <b>{phonenum}</b> to find out more!',
  LANG_DOWNLOAD_QUOTA: '<b>{quota}</b> 画像分こちらのプランより引かれます定額料金パッケージ',
  LANG_DOWNLOAD_SLOT: '<b>{quota}</b> 画像分こちらのプランより引かれますイメージパック',
  LANG_LOGO: 'マイロゴ',
  USING_OLDER_BROWSER: '古いブラウザをお使いですか？',
  UPGRADE_NEWER_BROWSER: '新しいブラウザにアップグレードして、より快適に123RFをご利用ください。',
  CLICK_TO_DOWNLOAD: '下記のアイコンをクリックして最新版をダウンロード：',
  LANG_VECTOR_EDITABLE: 'SVGとEPSの編集可能なファイル',
  PROMOBAR_GOOD15_TEXT1: '価格は安く、ダウンロードは多く。',
  PROMOBAR_GOOD15_TEXT2: 'こちらのギフトコードを入力するとすべてのプランが15％オフ：',
  PROMOBAR_GOOD15_BTNLABEL: 'GOOD15',
  PROMOBAR_SUMMER25_TEXT1: '価格は安く、ダウンロードは多く。',
  PROMOBAR_SUMMER25_TEXT2: 'こちらの<b>ギフトコード</b>ですべてのプランが25％オフ：',
  PROMOBAR_SUMMER25_BTNLABEL: 'SUMMER25',
  PROMOBAR_HALLOW30_TEXT: '3こちらの<b>ギフトコード</b>ですべてのプランが30％オフ：',
  NO_EDIT_COMPARE_MSG: '比較対象となる',
  SWITCH_TO_VECTOR_MSG: '写真編集ーはベクターサイズをサポートしていません。ベクターサイズを選択するとすべての編集が失われます。',
  SWITCH_TO_XL_MSG: '写真編集ーはXLサイズをサポートしていません。XLサイズを選択するとすべての編集が失われます。',
  UNAVAILABLE_FOR_THIS_SIZE: '{size}サイズにはご利用頂けません',
  SWITCH: '変更する',
  SWITCH_LICENSE_EXT_MSG: 'この画像を{type}でダウンロードしますか？特別ライセンスに変更するとすべての編集が失われます。',
  LANG_SESSION_EXPIRED: 'セッションが切れました。 <a href={url}>こちらからログイン</a>してください。',
  LANG_TEXT_COLOR: 'テキストカラー',
  LANG_SHAPE_ADD: 'Add Shape',
  LANG_TEXT_HIGHLIGHT: 'ハイライト',
  LANG_TEXT_ALIGNMENT: '行揃え',
  LANG_TEXT_BOLD: '太字',
  LANG_TEXT_ITALIC: '斜体',
  LANG_TEXT_UNDERLINE: '下線',
  LANG_TEXT_BULLET: '箇条書き',
  LANG_TEXT_LINE_SPACING: '行間',
  LANG_TEXT_LETTER_SPACING: '文字間隔',
  LANG_EDIT_COLOR: 'Edit color',
  LANG_BRING_FORWARD: '前面へ移動',
  LANG_BRING_BACKWARD: '背面へ移動',
  LANG_DUPLICATE: '複製',
  LANG_OPACITY: '不透明度',
  LANG_DELETE: '削除',
  LANG_TEXT_ADD: 'テキストを追加',
  LANG_TEXT_EDIT: 'テキストを編集',
  LANG_TEXT_FONT_TYPE: 'フォント',
  LANG_TEXT_FONT_SIZE: 'フォントサイズ',
  LANG_TEXT_ALL_FONTS: 'すべてのフォント',
  LANG_TEXT_UNDO: '元に戻す',
  LANG_TEXT_REDO: 'やり直し',
  LANG_TEXT_FONT: 'フォント',
  LANG_SEARCH_FONT_TYPE: 'フォントを検索',
  LANG_SEARCH_SHAPE_TYPE: 'エレメントを検索',
  LANG_DONE: '完了',
  ADD_HEADER: 'ヘッダーを追加',
  ADD_TITLE: 'タイトルを追加',
  ADD_BODY: '本文を追加',
  ADD_ASSISTIVE: '補助テキストを追加',
  STOCK_PHOTO_BANNER_TITLE: '数百万点のロイヤリティフリー写真素材で想いを伝える',
  STOCK_PHOTO_BANNER_DESC: '息をのむような写真であなたのアイデアを現実に。',
  STOCK_PHOTO_SUBSCRIBE_TITLE: 'ストーリーを視覚化する画像素材',
  STOCK_PHOTO_SUBSCRIBE_DESC: '1億8000万点以上の画像にアクセスし、あなたストーリーをパワーアップさせましょう。123RFの写真素材と組み合わせて、クリエイティブな可能性を最大限に引き出します。',
  STOCK_PHOTO_CARD_DOWNLOAD_PACK: 'イメージパック',
  STOCK_PHOTO_CARD_FROM_AS_LOW_AS: '最もお得なプランで',
  STOCK_PHOTO_CARD_CREDIT: 'credit',
  STOCK_PHOTO_CARD_SUBSCRIPTION_PLAN: '定額料金パッケージ',
  STOCK_PHOTO_CARD_IMAGE: '点',
  STOCK_PHOTO_CARD_SUBSCRIBE_NOW: '今すぐ申し込む',
  STOCK_PHOTO_CARD_BUY_NOW: '購入',
  STOCK_PHOTO_POPULAR_ROYALTY: '人気のロイヤリティフリー写真素材のカテゴリー',
  STOCK_PHOTO_BROWSE_BEST_SELLING: 'Browse best-selling royalty free images',
  STOCK_PHOTO_DISCOVER_AFFORABLE: '写真素材のお得なプランと価格を見る',
  STOCK_PHOTO_DISCOVER_NOW: '確認する',
  STOCK_PHOTO_VECTORS_FOOTAGE_AUDIO: 'Vectors, Footage, Audio for you',
  STOCK_PHOTO_VECTORS: 'Vectors',
  STOCK_PHOTO_ILLUSTRATION: 'Illustration',
  STOCK_PHOTO_PATTERN: 'Pattern',
  STOCK_PHOTO_CARTOON: 'Cartoon',
  STOCK_PHOTO_BABY: 'Baby',
  STOCK_PHOTO_FOOTAGE: 'Footage',
  STOCK_PHOTO_FOOTAGES: 'Footages',
  STOCK_PHOTO_BUILDING: 'Building',
  STOCK_PHOTO_HOTEL: 'Hotel',
  STOCK_PHOTO_CHILDREN: 'Children',
  STOCK_PHOTO_ABSTRACT: 'Abstract',
  STOCK_PHOTO_AUDIO: 'Audio',
  STOCK_PHOTO_AUDIOS: 'Audios',
  STOCK_PHOTO_TECHNO: 'Techno',
  STOCK_PHOTO_HOLIDAY: 'Holiday',
  STOCK_PHOTO_ACCOUSTIC: 'Accoustic',
  STOCK_PHOTO_LANDSCAPES: '風景',
  STOCK_PHOTO_BABIES: '赤ちゃん',
  STOCK_PHOTO_BUSINESS: 'ビジネス',
  STOCK_PHOTO_FOOD: '食べ物',
  STOCK_PHOTO_ANIMALS: '動物',
  STOCK_PHOTO_TECHNOLOGY: 'テクノロジー',
  STOCK_PHOTO_HEALTHCARE: 'ヘルスケア',
  STOCK_PHOTO_NATURE: '自然',
  STOCK_PHOTO_ROMANCE: 'ロマンス',
  CLIPART_VECTOR_EXPLORE_MILLIONS: '数百万点のロイヤリティフリーイラスト素材（ベクター）で想いを伝える',
  CLIPART_VECTOR_NEED_AN_EYE: 'ベクターイラスト、インフォグラフィックス、クリップアート、アイコンなど。あなたのアイデアを現実に。',
  CLIPART_VECTOR_FIND_VECTOR: 'プロジェクトにスパイスを加えるイラスト素材',
  CLIPART_VECTOR_FIND_VECTOR_DESC: 'かっこよくておしゃれなインフォグラフィックス、イラスト、漫画、クリップアート、背景、アイコンなど、魅力的なビジュアルを作成するものは何でも取り揃えています。',
  CLIPART_VECTOR_ON_DEMAND: 'On Demand',
  CLIPART_VECTOR_FROM_AS_LOW_AS: 'From as low as',
  CLIPART_VECTOR_PER_CREDIT: '/ credit',
  CLIPART_VECTOR_BUY_SUBSCRIPTION: 'Buy Subscription',
  CLIPART_VECTOR_PER_IMAGE: '/ image',
  CLIPART_VECTOR_SUBSCRIBE_NOW: 'Subscribe Now',
  CLIPART_VECTOR_POPULAR_ROYALTY: '人気のロイヤリティフリーイラスト素材のカテゴリー',
  CLIPART_VECTOR_DISCOVER_AFFORDABLE: 'ベクターイラスト素材のお得なプランと価格を見る',
  CLIPART_VECTOR_DISCOVER_NOW: 'Discover Now',
  SPEND_AND_WIN_SUBTITLE: 'Every {currency} spent = 1 Entry',
  SPEND_AND_WIN_IMGALT: 'Spend & Win an iPhone 13 Pro',
  CLIPART_VECTOR_GRAPHIC: 'グラフィック',
  CLIPART_VECTOR_ADVERTISING: '広告',
  CLIPART_VECTOR_FLOWER: '花',
  CLIPART_VECTOR_ABSTRACT: '抽象画',
  CLIPART_VECTOR_POP: 'ポップ',
  CLIPART_VECTOR_BIRD: '鳥',
  CLIPART_VECTOR_ILLUSTRATION: 'イラストレーション',
  CLIPART_VECTOR_PIXEL: 'ピクセル',
  CLIPART_VECTOR_3D: '3D',
  LANG_VECTOR_SVG_EPS: 'ベクター、SVG、EPS',
  FREE_UPGRADE: '無料アップグレード',
  FREE_UPGRADE_MESSAGE: '全ての定額料金パッケージとダウンロードパックで、XLサイズへのアップグレードが無料です。ぜひご利用ください。',
  LANG_FREE_IMAGE: '123RF 無料',
  LANG_IMAGE_FREE: 'このストックフォトは、商業的および個人的な使用に無料でご利用頂けます。クレジット表記が必要です。',
  LANG_HOW_TO_ATTRIBUTE: 'クレジット表記はどのようにすればいいですか？',
  LANG_FREE_DOWNLOAD: '無料ダウンロード',
  LANG_FREE_DOWNLOAD_BUTTON: '無料ダウンロード',
  LANG_GO_PLUS_BUTTON: 'PLUSにする',
  LANG_ATTRIBUTION_IS_KEY: 'クレジット表記は、自身の作品を誰もがアクセスできるようにしているクリエイターにとって重要です。クリエイターは、作品みなさまに多く利用・共有・クレジットされ、更に多くの方の目に触れていくことに感謝しています。',
  LANG_GET_ATTRIBUTION_LINK: 'このリンクをコピーして、コンテンツが使用されている場所の近くの場所に配置してください。',
  LANG_CREDIT_OWNER: 'クリエイターのクレジット表記をしてください：',
  LANG_USE_ATTRIBUTION: '下記のクレジットリンクをご利用ください。',
  LANG_ATTRIBUTION_LOCATION: 'こちらの表記方法が難しい場合は、ウェブサイト・ブログ・ニュースレターのフッター、もしくは、{media_type}のクレジットを表記できる専用スペースにご記入ください。',
  LANG_GOT_A_QUESTION: "何か質問がありますか？ <a href={url} target='_blank'><u>ぜひお問い合わせください。</u></a>",
  LANG_ATTR_FREE_IMG_TITLE: 'こちらの無料画像を利用するには著作権者のクレジット表記が必要です。',
  LANG_ATTR_FREE_DOWNLOADING: 'あと少しでご利用頂けます！',
  LANG_PLUS_CONTENT_1: 'ダウンロードし放題',
  LANG_PLUS_CONTENT_2: '10000満点以上の写真素材とベクター・イラスト素材',
  LANG_PLUS_CONTENT_3: '123RFだけでゲットできる限定3Dイラスト',
  LANG_PLUS_CONTENT_4: 'ダウンロード無制限で',
  LANG_PLUS_CONTENT_5: 'クリエイティブ能力を無限大に',
  FREE_VECTOR: 'FREE（ 無料）ストックベクター',
  FREE_STOCKPHOTO: 'FREE（無料）ストックフォト',
  FREE_PUBLIC_DOMAIN_CC0: 'パブリックドメイン CC0',
  BUY_5_DOWNLOAD_PACK: "<b>限られた時間のオファー！</b><br/>3 + <b><span style='color:red'>2 無料</span></b> = 5枚の写真またはベクター",
  BUY_5_DOWNLOAD_PACK_POINT_2: 'いつでもダウンロード可能（最大1年間）',
  BUY_5_GET_2_FREE: '2枚を無料に',
  LANG_ASK_DOWNLOAD_PLUS: 'このPLUSコンテンツを使いませんか？',
  LANG_SIGN_UP_PLUS: '123RF PLUSプランは',
  LANG_FOR_ONLY: "<span class='text'>たった</span><span class='price'>{price}</span><span class='text'>で</span>/月",
  LANG_FOR_ONLY_2: "<span class='text'>たった</span><span class='price'>{price}</span><span class='text'>で</span>",
  LANG_BENEFIT_PLUS: 'PLUS特典は',
  LANG_RELEASES: 'リリース',
  LANG_UNAVAILABLE: 'リリースなし',
  LANG_ATTR_FREE_IMG_TITLE2: '必要なものが見つかってよかったです。🎉',
  LANG_PLEASE_NOTE: 'この画像は、モデルリリース、プロパティリリースが添付されていませんのでご注意ください。商用利用は自己責任でお願いします。',
  LANG_PLEASE_ADVISED: '認識可能な個人を含む画像にはリリースが伴いないことをお勧めします。これらの画像を商業的に使用するには、関連当事者から許可を取得する必要があります。画像に認識可能な個人が含まれていない場合、商業的に使用する際に著作権の問題はないはずです。',
  LANG_REACHED_LIMIT: '1日のダウンロード制限に達しました。',
  LANG_COME_TOMORROW: '私たちは、あなたが私たちの無料画像を愛していることに興奮しています！私たちは、あなたが私たちの無料画像を愛していることに興奮しています。明日、ダウンロード制限を更新するために戻ってきてください。ありがとうございます😊。',
  LANG_DOWNLOAD_ERROR: 'エラーが発生したためダウンロードできませんでした。',
  LANG_REFRESH_AND_TRY_AGAIN: "ブラウザを更新して、もう一度お試しください。この問題が解決しない場合は <a href={url} target='_blank'>お問合せください。</a>",
  LANG_REACHED_LIMIT_PLUS: '１日のダウンロード上限に達しました',
  LANG_COME_TOMORROW_PLUS: '今日のダウンロード上限になってしまいました。いつもご利用頂きありがとうございます！',
  SEE_YOU_TOMORROW: 'また明日お待ちしています！',
  ORIGINAL: 'オリジナル',
  'AUTO_B&W': '自動白黒',
  AUTO_ADJUST: '自動調整',
  AUTO_POP: '自動ポップ',
  NONE: 'なし',
  LANG_CROP: '切り抜き',
  LANG_ENABLE_EDITOR: '編集する',
  LANG_DISABLE_EDITOR: '閉じる',
  LANG_IMAGE_FREE_CC0: "これはCC0画像で、クレジットは不要です。<a class='linkCC0' href={url} target='_blank'>CC0ライセンス利用規約</a>に従って使用できます。",
  LANG_PREVIEW: 'プレビュー',
  LANG_ATTRIBUTION_CC0_TITLE: 'CCO画像：クレジット不要',
  LANG_ATTRIBUTION_TITLE: '要クレジット表記',
  LANG_ATTRIBUTION_CONTENT: 'クレジット表記のリンクはファイルダウンロード後に表示されます。',
  LANG_ATTRIBUTION_CC0_CONTENT: 'こちらはCCO画像であるため、クレジットは不要です。',
  LANG_SUBCRIBE_PREMIUM: '１日のダウンロード上限に達しました！もっとダウンロードするには123RFプレミアムをご利用ください',
  LANG_REMOVE_ATTRIBUTION: 'クレジット表記を記載しなくてもいいですか？',
  LANG_SUBCRIBE_PLUS_ATTRIBUTION: "8,000万点いじょうのストックフォトやイラストにアクセスできる<img src={image} alt='123RF Plus Logo'>をご利用の場合は、クレジットは必須ではありません。",
  LANG_CREATOR_ATTRIBUTION: '記載する場合は、下記のクレジットリンクをご利用ください。',
  LANG_GET_ATTRIBUTION_LINK_2: 'このリンクをコピーして、コンテンツの近く、ウェブサイトの下部、クレジット表記を記載している場所などに貼り付けてください。',
  LANG_APPLY_BG_REMOVAL: '背景除去を適用する',
  LANG_APPLYING_EDIT: '編集を適用中です…',
  LANG_APPLYING_EDIT_ERROR: '問題が発生しました。',
  LANG_TRY_AGAIN: 'もう一度試しますか？',
  LANG_SEARCHCOLLECTION_PREMIUM: 'フルコレクション',
  LANG_SEARCHCOLLECTION_PLUS: 'エッセンシャルコレクション',
  LANG_SEARCHCOLLECTION_FREE: 'ベーシックコレクション',
  LANG_SIMILARIMAGESPAGE_TITLE: 'ロイヤリティフリーストックフォト・写真素材・イラスト・動画・ 音楽素材 - 123RF',
  LANG_SIMILARIMAGESPAGE_STOCKPHOTOS: '類似したイラスト・ベクター素材',
  LANG_SIMILARIMAGESPAGE_EMPTYRESULTS: 'アップロードされたコンテンツがありません.',
  LANG_SIMILARIMAGESPAGE_UNAVAILABLEIMAGE: '画像履歴はありません。',
  LANG_PER_IMAGE: '/画像',
  LANG_PLANS_AND_PRICING: 'プランと価格',
  LANG_CHOOSE_BEST_PLAN: 'ニーズに合わせてプランをご選択ください。',
  LANG_WANT_MORE_SAVING_1: 'もっと使う方に！',
  LANG_WANT_MORE_SAVING_2: '{price}～のプランはこちらから',
  LANG_BEST_VALUE: '最もお得な{percentage}％オフ',
  LANG_YEARLY_PLAN: '年間プラン',
  LANG_WITH_MONTHLY_PAYMENTS: '毎月払い',
  LANG_GRAB_YEARLY_PLAN: '年間プランで{percentage}％節約',
  LANG_PRICE_PER_MONTH: '{price}/月',
  LANG_PRICE_PER_YEAR: '年間{price}',
  LANG_GET_X_EVERY_MONTH: '毎月{quota}点の写真またはベクターイラスト',
  LANG_X_EVERY_MONTH: '毎月{quota}点の写真またはベクターイラスト',
  LANG_SINGLE_IMAGE_PURCHASE: '画像を単品購入',
  LANG_GET_ONLY_WHAT_YOU_NEED: '今いるものだけをゲット',
  LANG_TOTAL_PRICE: '計 {price}',
  LANG_DOWNLOAD_IMMEDIATELY: '購入手続き後すぐにダウンロード',
  LANG_NEXT: '次',
  LANG_TITLE: 'ロイヤリティフリーストックフォト・写真素材・イラスト・動画・ 音楽素材 - 123RF',
  SIMILAR_STOCK_PHOTOS: '類似したイラスト・ベクター素材',
  EMPTY_SIMILAR_SEARCH_RESULTS: 'アップロードされたコンテンツがありません.',
  UNAVAILABLE_IMAGE: '画像履歴はありません。',
  LANG_DOWNLOAD_PACK: 'イメージパック',
  LANG_GET_X_ANY_TIME: '{quota}点の写真またはイラスト',
  LANG_X_CREDITS: '{total}チケット',
  LANG_PER_CREDIT: '/チケット',
  LANG_GET_X_PHOTOS_AND_Y_VIDEOS_ANY_TIME: '{photoCount}点の写真{videoCount, plural, =0 {} other {または#点の動画}}',
  INCLUDES_STANDARD_LICENSE: 'スタンダードライセンス',
  LANG_MEDIUM_SIZE: 'Mサイズ',
  LANG_LARGE_SIZE: 'Lサイズ',
  LANG_EXTRA_LARGE_SIZE: 'XLサイズ',
  LANG_STANDARD_SIZES: '通常サイズ',
  LANG_ROYALTY_FREE: 'ロイヤリティフリー',
  LANG_OPTIONS: 'オプション',
  LANG_BENEFIT_FROM_PLUS_PLAN: 'PLUS特典は',
  LANG_RELATED_FONT_SEARCH: '関連検索',
  AI_GENERATED_PHOTO: 'AI生成画像',
  FONT_PRICING_CREDIT: 'チケット',
  '3_FONTS_3_IMGS': '3つのフォント<b>または</b>3つの画像<b>または</b>1つのビデオ',
  '9_FONTS_9_IMG_3_VIDEOS': '9つのフォント<b>または</b>9つの画像<b>または</b>3つのビデオ',
  PURCHASE_AND_DOWNLOAD: '購入＆ダウンロード',
  FONT_PATH: 'fonts',
  FONT_ZIP: 'フォント（OTF、TTF）',
  FONT_PACKS_LICENSE: '123RFパックライセンス',
  FONT_META_DESC: '{product_title} のフォント、書体、タイポグラフィをダウンロードして、印刷物にもウェブにもデザインに活用しましょう。',
  FONT_META_TITLE: 'フォント - {product_title} | 123RF',
  FONT_SIMILAR: '類似フォント',
  LANG_PLANS: 'プラン',
  LANG_I_WANT_THIS: 'I want this',
  LANG_AI_VARIATION_TOOLTIP_TITLE: 'バリエーションを作ってみる',
  LANG_AI_VARIATION_TOOLTIP_DESCRIPTION: '提供されたオリジナル画像をもとに新しい画像を生成します。さらに、AIで生成された画像がライセンスされるたびに、123RFのコントリビューターが報酬を受け取る新方式です！',
  LANG_SIZEBAR_TOOLTIP_DESCRIPTION: '私たちのコミュニティはあなたに感謝します。ライセンス料は123RFコントリビューターに共有されます。',
  LANG_CONTRIBUTORS_AI_VARIATION: 'オリジナル画像の123RFコントリビューターには、ライセンスされたAIバリエーションごとに報酬が支払われます。',
  LANG_AI_VARIATION_LIMIT: '残念ながら、今日のAI画像生成リミットに達しました。',
  LANG_GENERATE_COMEBACK: 'また明日、より多くの画像生成をお試しください！',
  LANG_LICENSE_AND_DOWNLOAD: 'ライセンス＆ダウンロード',
  LANG_CONFIRM_OK: 'OK',
  LANG_AI_SWITCH_CONTENT: '写真編集は、AIバリエーションには対応していません。AIバリエーションに切り替えた場合、すべての編集が失われます。',
  LANG_GENERATE_VARIATIONS: 'バリエーションを生成する',
  LANG_GENERATE_WITH_AI: 'AIで画像を生成する',
  LANG_NEW: 'NEW',
  LANG_AI_GENERATOR_LICENSE: '123RF AI生成機能利用規約（以下「本規約」といいます。）',
  LANG_I_UNDERSTAND: '理解しました',
  LANG_WHAT_IS_THIS: 'これは何ですか？',
  LANG_AUTHENTIC: '現実的',
  LANG_ABSTRACT: '抽象画',
  LANG_MACRO_CLOSEUP: 'マクロ/クローズアップ',
  LANG_OBJECT: 'オブジェクト',
  LANG_WATERCOLOR: '水彩画',
  LANG_FLAT: '平面',
  LANG_CARTOON: 'マンガ',
  LANG_GEOMETRIC: '幾何学的',
  LANG_GRADIENT: 'グラデーション',
  LANG_ISOMETRIC: '等角図',
  LANG_3D: '3D',
  LANG_HAND_DRAWN: '手描き',
  LANG_NATURAL: 'ナチュラル',
  LANG_WARM: '暖かみ',
  LANG_DRAMATIC: 'ドラマチック',
  LANG_VIVID_BOLD: '鮮やか＆ダイナミック',
  LANG_BLACK_WHITE: '白黒',
  LANG_ASIAN: 'アジア人',
  LANG_AFRICAN_AMERICAN: 'アフリカ系アメリカ人',
  LANG_STANDARD: 'スタンダード',
  LANG_EXTENDED: '特別',
  LANG_STANDARD_CONTENT_1: 'ソーシャルメディア、ウェブ、メール、モバイルで無制限に閲覧が可能。',
  LANG_STANDARD_CONTENT_2: '50万部までの印刷が可能です。',
  LANG_STANDARD_CONTENT_3: '商品での利用はできません。',
  LANG_EXTENDED_CONTENT_1: 'ソーシャルメディア、ウェブ、メール、モバイルで無制限に閲覧が可能。',
  LANG_EXTENDED_CONTENT_2: '無制限の印刷が可能です。',
  LANG_EXTENDED_CONTENT_3: '商用目的での利用が可能です。',
  LANG_ORIGINAL_IMAGE: 'オリジナル画像',
  LANG_IMPORTANT_NOTE: '重要事項',
  LANG_AI_ORIGINAL_ALTERED: 'AIバリエーションは、AI技術によって元の画像が変更されたものです。お客様が選んだ画像をAIエディターに修正してもらうようなイメージです。',
  LANG_GENERATE_VARIATIONS_NOTE: '現在、AIを利用したイメージ・バリエーションはベータ段階のため、生成された画像は予想外の結果が生じる可能性があります。当社は、そのような結果とバリエーションが、倫理的で責任あるAIガイドラインに適合するよう努めています。',
  LANG_LICENSE_AI_EDITED: 'これらのAI編集による画像のライセンスを取得する場合、元の画像の著作権保持者にはライセンス料が支払われます。',
  LANG_MOOD: 'ムード',
  LANG_AI_EXCLUDE_PLACEHOLDER: '例：緑色、ストレートヘアー',
  LANG_SIMILAR_STOCK: '類似のストック画像',
  LANG_PREMIUM_TAG_TOOLTIP: 'PREMIUM subscription only',

  WITH_MODEL_RELEASE: 'モデルリリースあり',
  WITH_PROPERTY_RELEASE: 'プロパティリリースあり',
  MODEL_RELEASE_CONTENT: 'モデルリリースとは、写真の被写体であるモデルが、さまざまな形の写真の出版・配布・使用に対して同意をした法的書類です。',
  PROPERTY_RELEASE_CONTENT: 'プロパティリリースあり',

  variousStockGAI: '何百万枚ものストックフォトをもとに構築された法的にも準拠したAIモデルは、企業のニーズに合わせて最適化されており、万が一の補償もついて安心してご利用いただけます。',
  extendedLicense: '特別ライセンス',
  sizes: 'サイズ ',

  downloadMail1st: '画像拡大サービスプロバイダーは、翌営業日以内に最終拡大ファイルをダウンロードするための手順を',
  downloadMail2nd: 'に電子メールでお送りします。',

  aiGenarated: 'AI生成画像',

  variousOpenJourney: 'AIモデルがMidjourneyの特徴的なスタイルに近いアートを生成します。',
  variousTurboVision: '標準的なXL解像度でのハイスピード生成により、全体の品質を向上させます。',
  variousRealVisXL: '高精度でリアリスティックな仕上がりを目指すモデル。',
  variousPortraitPlus: '1:1のアスペクト比に最適な、均整のとれたポートレート構図モデル。',
  enterVirifiedCode: '認証コードを入力',
  verify: '認証する',
  didNotGetCode: 'コードが届きませんか？',
  clickToResend: 'クリックして再送信',
  sentCodeToMail: 'ご登録のメールアドレスに認証コードを送信しました。',
  invalidCode: 'コードが無効です。',
  expiredOTP: 'OTPの有効期限が切れています。',
  sendEmail: 'メールは正常に送信されました。ご自身のメールボックスをご確認ください。',
  remainingTime: '残り時間: ',
};
export default jp;
