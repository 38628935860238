import Axios from 'services/Axios/Axios';
import React, { useContext, useEffect, useState } from 'react';
import { API_ROUTES, ROUTES_SEARCH_PAGE } from 'constants/routes';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { MAIN_COLOR, SUCCESSFUL } from 'constants/index';
import IcLoadingCircle from 'components/icons/Page/IcLoadingCircle';
import MainContext from 'stores/context/MainContexts';
import { isEmpty } from 'lodash';
import router from 'next/router';

const CheckBoxLB = ({
  likeboxId,
  imageId,
  isChecked,
  onChangeAdd,
  onChangeRemove,
  likeboxName,
  getDefaultLikebox,
  descriptionImg,
  dataClone,
}) => {
  const [isCheck, setIsCheck] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const {
    actionsSearchResult, actionsUser, actions, initDataProvider,
  } = useContext(MainContext);
  const [listImagesId, setListImageId] = useState([]);
  const idImages = [];

  useEffect(() => {
    if (!isEmpty(dataClone)) {
      Object.keys(dataClone).forEach((key) => {
        idImages.push(dataClone[key]?.id);
      });
    }
    setListImageId(idImages);
  }, [dataClone]);

  useEffect(() => {
    setIsCheck(isChecked);
  }, [isChecked]);

  const idImage = [];
  const onChaneHandleOnClick = async () => {
    // actions.setDisplayBtn(true);
    setLoadingCheck(true);
    idImage.push(imageId);
    try {
      if (!isCheck) {
        actions.setDefaultLb(likeboxId);
        await Axios.post(
          API_ROUTES.ADD_IMAGE_LIKEBOX,
          {
            image_id: imageId,
            lb_id: likeboxId,
          },
          async (status, data) => {
            if (data.status === SUCCESSFUL) {
              actionsUser.setImageIdCurrent(imageId);

              getDefaultLikebox(likeboxName, descriptionImg.substring(0, 30));
              if (ROUTES_SEARCH_PAGE.includes(router.pathname)) {
                await Axios.post(
                  API_ROUTES.CHECK_ADDED_STATUS,
                  {
                    image_list: listImagesId,
                  },
                  (status1, response) => {
                    if (response.status === SUCCESSFUL) {
                      actionsUser.getDataAddedDefaultLb(response?.content);
                    }
                  },
                );
              }
              actionsSearchResult.setDataIsAddedAudioDetail(1);
              setIsCheck(!isCheck);
              setLoadingCheck(false);
              onChangeAdd(true, imageId);
              actionsUser.getAllLikeBox();
            }
          },
        );
      } else {
        await Axios.post(
          API_ROUTES.REMOVE_IMAGE_LIKEBOX,
          {
            selected_images: idImage,
            lb_id: likeboxId,
          },
          (status, data) => {
            if (data.status === SUCCESSFUL) {
              actionsUser.setImageIdCurrent(imageId);
              setIsCheck(!isCheck);
              setLoadingCheck(false);
              onChangeRemove(true, imageId, likeboxId);
              getDefaultLikebox(likeboxName, descriptionImg.substring(0, 30));
              if (likeboxId === initDataProvider?.defaultLb) {
                actionsSearchResult.setDataIsAddedAudioDetail(0);
              }
              actionsUser.getAllLikeBox();
            }
          },
        );
      }
    } catch (error) {
      setLoadingCheck(false);
    }
  };
  return (
    <>
      <div aria-hidden="true" role="button" className="body" onClick={onChaneHandleOnClick}>
        {likeboxName}
      </div>
      {loadingCheck ? (
        <div className="loading-checkbox checkbox-collection">
          <IcLoadingCircle width="16" height="22" fillcolor={MAIN_COLOR} />
        </div>
      ) : (
        <Checkbox
          className="checkbox-collection"
          checked={isCheck}
          onChange={onChaneHandleOnClick}
        />
      )}
    </>
  );
};
CheckBoxLB.propTypes = {
  likeboxId: PropTypes.number,
  imageId: PropTypes.number,
  isChecked: PropTypes.bool,
  onChangeAdd: PropTypes.func,
  onChangeRemove: PropTypes.func,
  likeboxName: PropTypes.string,
  getDefaultLikebox: PropTypes.func,
  descriptionImg: PropTypes.string,
  dataClone: PropTypes.arrayOf(PropTypes.any),
};

CheckBoxLB.defaultProps = {
  likeboxId: null,
  imageId: null,
  isChecked: null,
  onChangeAdd: null,
  onChangeRemove: null,
  likeboxName: '',
  getDefaultLikebox: null,
  descriptionImg: '',
  dataClone: null,
};
export default CheckBoxLB;
